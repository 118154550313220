<template>
  <div class="contacts">
    <h1 style="color: #fff; background: var(--color)">Контакты</h1>
    <div class="contacts-item contacts_spb">
      <h3>
        Санкт-Петербург
        <span
          ><img src="../assets/img/phoneFooter.svg" alt="" /> +7 (812)
          327-60-60</span
        >
      </h3>
      <div class="contacts-item-main">
        <div class="contacts-item--left">
          <div class="contacts-item_main">
            <h3>Офис продаж</h3>
            <div class="contacts-item--info">
              <h5 class="address">
                <img src="../assets/img/mark.svg" alt="" />199397, г.
                Санкт-Петербург, ул. Кораблестроителей, д. 32 кор. 1, лит. А,
                пом. 39Н
              </h5>
              <h5>
                <img src="../assets/img/phoneFooter.svg" alt="" /> +7 (812)
                327-60-60
              </h5>
              <h5>
                <img src="../assets/img/email.svg" alt="" /> sklad@izoprint.ru
              </h5>
              <h5>
                <img src="../assets/img/clock.svg" alt="" /> Пн.-Пт. - с 9:00 до
                18:00
              </h5>
            </div>
          </div>
          <div class="contacts-item_map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2866.2874068144697!2d30.21422397418161!3d59.953481137636686!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x469636de4c97f9a7%3A0xfd27d348043d7ec!2z0YPQuy4g0JrQvtGA0LDQsdC70LXRgdGC0YDQvtC40YLQtdC70LXQuSwgMzIg0LrQvtGA0L_Rg9GBIDEsINCh0LDQvdC60YIt0J_QtdGC0LXRgNCx0YPRgNCzLCAxOTkzOTc!5e0!3m2!1sru!2sru!4v1678542024863!5m2!1sru!2sru"
              width="330"
              height="330"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <div class="contacts-item--right">
          <div class="contacts-item_main">
            <h3>Склад</h3>
            <div class="contacts-item--info">
              <h5 class="address">
                <img src="../assets/img/mark.svg" alt="" />199397, г.
                Санкт-Петербург, ул. Салова, д. 35, лит. Б
              </h5>
              <h5>
                <img src="../assets/img/phoneFooter.svg" alt="" /> +7 (921)
                928-33-76
              </h5>
              <h5>
                <img src="../assets/img/email.svg" alt="" /> sklad@izoprint.ru
              </h5>
              <h5>
                <img src="../assets/img/clock.svg" alt="" /> Пн.-Пт. - с 8:00 до
                17:00
              </h5>
            </div>
          </div>
          <div class="contacts-item_map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1394.8229306117646!2d30.37914155547114!3d59.89447919391602!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x469630264138c36b%3A0xd99d64775054acb5!2z0YPQuy4g0KHQsNC70L7QstCwLCAzNSDQsSwg0KHQsNC90LrRgi3Qn9C10YLQtdGA0LHRg9GA0LMsIDE5MjEwMg!5e0!3m2!1sru!2sru!4v1678543586329!5m2!1sru!2sru"
              width="330"
              height="330"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <div class="contacts-item contacts_msk">
      <h3>
        Москва
        <span
          ><img src="../assets/img/phoneFooter.svg" alt="" /> +7 (495)
          627-75-88</span
        >
      </h3>
      <div class="contacts-item-main">
        <div class="contacts-item--left">
          <div class="contacts-item_main">
            <h3>Склад 1</h3>
            <div class="contacts-item--info">
              <h5 class="address">
                <img src="../assets/img/mark.svg" alt="" />141150, Московская
                область, Щелковский район, г. Лосино-Петровский, ул. Дачная, дом
                2, стр 1
              </h5>
              <h5>
                <img src="../assets/img/phoneFooter.svg" alt="" /> +7 (495)
                526-33-37
              </h5>
              <h5>
                <img src="../assets/img/email.svg" alt="" />
                izoprint@izoprint.ru
              </h5>
              <h5>
                <img src="../assets/img/clock.svg" alt="" /> Пн.-Пт. - с 9:00 до
                18:00
              </h5>
            </div>
          </div>
          <div class="contacts-item_map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d559.7391556739584!2d38.195962829260246!3d55.86341899878645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x414ad11166b81aa1%3A0xdf04b4ce5b251713!2z0YPQuy4g0JTQsNGH0L3QsNGPLCAxLCDQm9C-0YHQuNC90L4t0J_QtdGC0YDQvtCy0YHQutC40LksINCc0L7RgdC60L7QstGB0LrQsNGPINC-0LHQuy4sIDE0MTE1MA!5e0!3m2!1sru!2sru!4v1678544258022!5m2!1sru!2sru"
              width="330"
              height="330"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <div class="contacts-item--right">
          <div class="contacts-item_main">
            <h3>Склад 2</h3>
            <div class="contacts-item--info">
              <h5 class="address">
                <img src="../assets/img/mark.svg" alt="" />107497, город Москва,
                2-й Иртышский пр., д.4, с.1А
              </h5>
              <h5>
                <img src="../assets/img/phoneFooter.svg" alt="" /> +7 (495)
                526-33-37
              </h5>
              <h5>
                <img src="../assets/img/email.svg" alt="" />
                izoprint@izoprint.ru
              </h5>
              <h5>
                <img src="../assets/img/clock.svg" alt="" /> Пн.-Пт. - с 8:00 до
                18:00
              </h5>
            </div>
          </div>
          <div class="contacts-item_map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2241.922200163666!2d37.75735793063366!3d55.811951034495195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46b534ee4a3929a3%3A0x6bed941b80ac4d83!2zMi3QuSDQmNGA0YLRi9GI0YHQutC40Lkg0L_RgC3QtCwgNC4g0YHRgtGALiAx0JAsINCc0L7RgdC60LLQsCwgMTA3MTQz!5e0!3m2!1sru!2sru!4v1678544316924!5m2!1sru!2sru"
              width="330"
              height="330"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <div class="contacts-item contacts_ecb">
      <h3>
        Екатеринбург
        <span
          ><img src="../assets/img/phoneFooter.svg" alt="" /> +7 (343)
          219-87-20</span
        >
      </h3>
      <div class="contacts-item-main">
        <div class="contacts-item--left">
          <div class="contacts-item_main">
            <h3>Офис продаж</h3>
            <div class="contacts-item--info">
              <h5 class="address">
                <img src="../assets/img/mark.svg" alt="" />620142, г.
                Екатеринбург, ул.Фрунзе 35 "А", (территория ОАО "СИЗ")
              </h5>
              <h5>
                <img src="../assets/img/phoneFooter.svg" alt="" /> +7 (343)
                219-87-20
              </h5>
              <h5>
                <img src="../assets/img/email.svg" alt="" />
                ekb@izoprint.ru
              </h5>
              <h5>
                <img src="../assets/img/clock.svg" alt="" /> Пн.-Пт. - с 9:00 до
                18:00
              </h5>
            </div>
          </div>
          <div class="contacts-item_map">
            <iframe
              src="https://yandex.ru/map-widget/v1/?um=constructor%3Ad78a660fc8233947af4c26b98b72df6eec8c536c5e6ee24599f2fad0ae1d0485&amp;source=constructor"
              width="330"
              height="330"
              frameborder="0"
            ></iframe>
          </div>
        </div>
        <div class="contacts-item--right">
          <div class="contacts-item_main">
            <h3>Склад</h3>
            <div class="contacts-item--info">
              <h5 class="address">
                <img src="../assets/img/mark.svg" alt="" />620017 г.
                Екатеринбург, улица Фронтовых Бригад, 13 лит. З микрорайон
                Эльмаш
              </h5>
              <h5>
                <img src="../assets/img/phoneFooter.svg" alt="" /> +7 (343)
                219-87-20
              </h5>
              <h5>
                <img src="../assets/img/email.svg" alt="" />
                izoprint@izoprint.ru
              </h5>
              <h5>
                <img src="../assets/img/clock.svg" alt="" /> Пн.-Пт. - с 8:00 до
                18:00
              </h5>
            </div>
          </div>
          <div class="contacts-item_map">
            <iframe
              src="https://yandex.ru/map-widget/v1/-/CCUmbUdGsC"
              width="330"
              height="330"
              allowfullscreen="true"
              style="position: relative; border: 0px"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <div class="contacts-item managers">
      <h3 class="mb-4">Отдел продаж</h3>

      <h5 class="ml-3">Москва и Санкт-Петербург</h5>

      <ul class="managers-list">
        <li class="managers-item">
          <h6>Лобынько Роман - +7 (931) 397-02-11</h6>
          <h6>E-mail: spb5@izoprint.ru</h6>
        </li>
        <li class="managers-item">
          <h6>Журавлева Ирина - +7 (931) 398-21-46</h6>
          <h6>
            ICQ: 622-674-875 <br />
            E-mail: manager1@izoprint.ru
          </h6>
        </li>
        <li class="managers-item">
          <h6>Копейкина Ольга - +7 (931) 397-04-62</h6>
          <h6>E-mail: manager2@izoprint.ru</h6>
        </li>
        <li class="managers-item">
          <h6>Хурсина София - +7 (931) 398-21-25</h6>
          <h6>E-mail: manager3@izoprint.ru</h6>
        </li>
      </ul>
      <h5 class="ml-3">Екатеринбург</h5>

      <ul class="managers-list ecb">
        <li class="managers-item">
          <h6>Шихалева Татьяна - +7 (931) 101-87-55</h6>
          <h6>E-mail: ekb3@izoprint.ru</h6>
        </li>
        <li class="managers-item">
          <h6>Клюс Татьяна - +7 (931) 101-75-26</h6>
          <h6>E-mail: ekb@izoprint.ru</h6>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contacts",
  data: () => ({
    dialog: false,
  }),
  components: {},
  mounted() {
    if (window.location.hash) {
      document.querySelector(window.location.hash).scrollIntoView(false);
    }
  },
};
</script>
<style lang="scss">
.contacts,
.about_page {
  h1 {
    border-bottom: 2px solid rgba(0, 0, 0, 0.12);
    padding: 5px 0;
    padding-left: 5px;
    margin-bottom: 15px;
  }

  .contacts-item {
    margin-top: 50px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.12);
    padding-bottom: 60px;

    h3 {
      max-width: 606px;
      display: flex;
      justify-content: space-between;
      span {
        img {
          width: 20px;
        }
        display: flex;
        align-items: center;
        column-gap: 5px;
      }
    }
  }
  .contacts-item-main {
    display: flex;
    column-gap: 30px;
    margin-top: 40px;

    .contacts-item--left {
      display: flex;
      width: 50%;
      column-gap: 10px;
    }
    .contacts-item--right {
      display: flex;
      width: 50%;
      column-gap: 10px;
    }

    .contacts-item_main {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h5 {
        display: flex;
        align-items: center;
        column-gap: 5px;
        margin-top: 25px;

        &:last-child {
          align-items: baseline;
        }
      }
    }
    .contacts-item_map {
      iframe {
        border-radius: 8px;
      }
    }
  }
  .about {
    margin-top: 40px;
    margin-bottom: 40px;
    .about_main {
      margin-top: 10px;
      display: flex;
      column-gap: 30px;

      .about_main-left {
        img {
          width: 60px;
          height: 60px;
        }
        h6 {
          display: flex;
          align-items: center;
          column-gap: 10px;
          font-weight: bold;
          margin-bottom: 20px;
        }
      }

      .about_main-right {
        display: flex;
        align-items: center;
        justify-content: center;
        background: url("../assets/img/window.png");
        background-repeat: no-repeat;
        background-position: bottom;

        width: 45%;

        border-radius: 4px;

        img {
          width: 60%;
          max-width: 200px;
        }
      }
    }
  }

  .about__items {
    margin: 40px 0;
    padding-top: 40px;
    border-top: 2px solid rgba(0, 0, 0, 0.12);

    &_wrapper {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      column-gap: 20px;
    }

    h4 {
      color: var(--color);
      margin-bottom: 10px;
    }
    li {
      position: relative;
      &::before {
        display: inline-block;
        content: "•";
        color: var(--color);
        width: 15px;
      }
    }
  }
  .callback {
    border-top: 2px solid rgba(0, 0, 0, 0.12);
    border-bottom: 2px solid rgba(0, 0, 0, 0.12);
    padding-top: 40px;
    padding-bottom: 40px;

    h2 {
      margin-bottom: 20px;
    }
    p {
      text-align: center;
    }

    .callback_buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 30px;

      .btn {
        margin: 0;
      }
    }
  }
  .objects {
    margin-top: 40px;

    h2 {
      margin-bottom: 20px;
    }
    .objects_cards {
      display: flex;
      flex-wrap: wrap;
      gap: 25px;

      .object_card {
        display: flex;
        align-items: center;
        justify-content: center;
        filter: grayscale(100%);
        transition: 0.5s;
        border: 1px solid #f3f3f3;
        border-radius: 4px;
        width: 30%;

        img {
          width: 60%;
        }

        &:hover {
          box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25);
          filter: grayscale(0%);
        }
      }
    }
  }
}
.managers {
  border-bottom: 0px solid #000 !important;
}
.managers-list {
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.managers-item {
  width: 30%;
  margin-left: 30px;
  margin-bottom: 15px;
  padding-left: 15px;
  border-left: 2px solid var(--color);
}
@media screen and (max-width: 1400px) {
  .contacts .contacts-item-main {
    flex-wrap: wrap;
    row-gap: 40px;
    .contacts-item--left,
    .contacts-item--right {
      width: 100%;
      justify-content: space-between;

      .contacts-item_main {
        width: 50%;
      }
      .contacts-item_map {
        width: 50%;

        iframe {
          width: 100% !important;
        }
      }
    }
  }
}
@media screen and (max-width: 960px) {
  .about__items {
    &_wrapper {
      flex-wrap: wrap;

      .about__items-right {
        margin-top: 20px;
      }
    }
  }
  .about_page .callback .callback_buttons {
    flex-wrap: wrap;
    row-gap: 10px;
  }
}
@media screen and (max-width: 625px) {
  .managers-item {
    width: 100%;
  }
  .about_page .about .about_main .about_main-right {
    display: none;
  }
  .about_page .objects .objects_cards .object_card {
    width: 45%;
  }
  .contacts .contacts-item h3 {
    flex-wrap: wrap;
    row-gap: 10px;
  }
  .contacts .contacts-item-main .contacts-item--left,
  .contacts .contacts-item-main .contacts-item--right {
    flex-wrap: wrap;
    row-gap: 20px;

    .contacts-item_main,
    .contacts-item_map {
      width: 100%;
    }
  }
}
</style>
