<template>
  <nav class="nav">
    <v-dialog v-model="dialog" max-width="560">
      <v-card class="d-flex call-dialog">
        <a @click="dialog = false" class="dialog-close"
          ><img src="../assets/img/close.svg" alt=""
        /></a>
        <v-card-text>
          <h3>Обратный звонок</h3>
          <form class="form">
            <label for="call-name">Ваше имя <b color="red ">*</b></label>
            <input
              required
              id="call-name"
              type="text"
              placeholder="Введите имя"
              v-model="name"
            />
            <label for="call-phone">Телефон <b color="red ">*</b></label>
            <input
              required
              id="call-phone"
              type="tel"
              placeholder="Введите телефон"
              maxlength="11"
              @keydown="onlyNumber"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              v-model="phone"
            />
            <button
              :disabled="name == '' || phone == ''"
              @click="
                sendForm();
                dialog = false;
                dialog_thanks = true;
              "
              class="btn"
            >
              Отправить
            </button>
          </form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_thanks" max-width="560">
      <v-card class="d-flex call-dialog thanks-dialog">
        <a @click="dialog_thanks = false" class="dialog-close"
          ><img src="../assets/img/close.svg" alt=""
        /></a>
        <v-card-text>
          <h3>Спасибо!</h3>
          <div class="thanks-text">
            <p>
              Мы свяжемся с вами в ближайшее время <br />
              График работы: пн-пт, 9:00 - 18:00
            </p>
            <button @click="dialog_thanks = false" class="btn btn-invert">
              Вернуться к покупкам
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="wrapper d-flex">
      <div class="nav__main">
        <div class="nav__main-left d-none d-md-flex">
          <div class="nav__logo">
            <router-link to="/">
              <img src="../assets/img/logo.png" alt="izoprint" height="45px"
            /></router-link>
          </div>

          <!-- <div class="nav-city">
            <img src="../assets/img/Location.svg" class="mr-2" alt="" />
            {{ city }}
          </div> -->
        </div>
        <div class="nav__main-right d-none d-md-flex">
          <button class="btn" height="40px" @click="startCheck()">
            <img class="mr-2" src="../assets/img/catalog.svg" alt="" />
            <span> Каталог товаров </span>
          </button>
          <div class="nav__main-right--input">
            <input
              type="text"
              class="nav-search"
              placeholder="Поиск в магазине, наберите минимум 3 символа"
              v-model="search"
              @keydown="startSearch"
            />
            <i @click="startSearch" class="mdi mdi-magnify nav-search-icon"></i>
          </div>

          <!-- <div class="nav-icon">
            <router-link
              to="/favorites"
              class="nav-icon-link nav-icon-favotite"
            >
              <img src="../assets/img/Heart.svg" alt="" />
              <span>Избранное</span>
              <i>{{ favorites_count }}</i>
            </router-link>
          </div> -->
          <!-- <div class="nav-icon">
            <router-link to="" class="nav-icon-link">
              <img src="../assets/img/user.svg" alt="" />
              <span>Профиль</span>
            </router-link>
          </div> -->
          <div class="nav-icon">
            <router-link to="/backet" class="nav-icon-link nav-icon-backet">
              <img src="../assets/img/cart.svg" alt="" />
              <span>Корзина</span>
              <i>{{ backet_count }}</i>
            </router-link>
          </div>
          <div class="nav__main-right--bottom">
            <div class="nav__main-menu">
              <!-- <router-link to="/sale" class="nav__main-menu--items text">
                <img src="../assets/img/fire.svg" class="mr-1" alt="" />
                Акции</router-link
              > -->
              <a href="/catalog" class="nav__main-menu--items text">Каталог</a>
              <a href="/about" class="nav__main-menu--items text">О компании</a>
              <a href="/delivery" class="nav__main-menu--items text"
                >Доставка</a
              >
              <a href="/contacts" class="nav__main-menu--items text"
                >Контакты</a
              >
            </div>
            <div class="nav__main-phone">
              <div class="nav_main-phone--div d-flex mr-3">
                <a href="tel:+7(931)397-03-79"> +7 (931)397-03-79</a>
              </div>
              <button small class="btn pl-5" @click="dialog = true">
                <img src="../assets/img/phone.svg" class="mr-2" alt="" />
                <span> Заказать звонок</span>
              </button>
            </div>
          </div>
        </div>

        <div class="nav__main-mobile d-flex d-md-none">
          <div class="nav__main-mobile--top">
            <button class="btn" height="40px" @click="startCheck()">
              <img src="../assets/img/catalog.svg" alt="" />
            </button>

            <router-link to="/"
              ><img src="../assets/img/logo.png" alt=""
            /></router-link>

            <button small class="btn pl-5" @click="dialog = true">
              <img src="../assets/img/phone.svg" alt="" />
            </button>
          </div>
        </div>
      </div>
      <div class="nav__menu wrapper" v-if="openMenu">
        <div class="nav__menu-left">
          <div
            class="nav__menu--category"
            v-for="category in categoriesRes"
            :key="category.id"
          >
            <h4 class="d-none d-md-flex" @click="targetCatId = category.id">
              {{ category.name }}
              <img :src="url + 'images/' + category.image" />
            </h4>
            <a
              class="d-flex d-md-none"
              :href="'/category?id=' + targetCatId"
              style="color: #000"
            >
              <h4 @click="targetCatId = category.id">
                {{ category.name }}
                <img :src="url + 'images/' + category.image" />
              </h4>
            </a>
          </div>
        </div>
        <div class="nav__menu-right d-none d-md-block">
          <h4>{{ categoriesRes.find((el) => el.id == targetCatId).name }}</h4>
          <div
            class="nav__menu-item"
            v-for="item in targetCategories"
            :key="item.id"
          >
            <a :href="'/item?id=' + item.id">
              <h6>{{ item.name }}</h6>
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import api from "../api";

export default {
  name: "Navigation",
  computed: {
    ...mapGetters(["backet_count"]),
  },
  watch: {
    targetCatId() {
      this.targetCategories = this.categoriesRes.find(
        (el) => el.id == this.targetCatId
      ).items;
    },
  },
  data: () => ({
    url: process.env.VUE_APP_ROOT_API,
    dialog: false,
    dialog_thanks: false,
    openMenu: false,
    targetCatId: 1,
    targetCategories: [],
    categoriesRes: [],
    name: "",
    phone: "",
    city: "Санкт-Петербург",
    search: "",
  }),
  methods: {
    async startSearch(e) {
      if (this.search.length < 3) return;
      if (e.code) {
        if (e.code == "Enter") {
          window.location.replace("/category?search=" + this.search);
        }
      } else {
        window.location.replace("/category?search=" + this.search);
      }
    },
    startCheck() {
      this.openMenu = true;

      setTimeout(() => {
        let menu = document.querySelector(".nav__menu.wrapper");

        let detect = (event) => {
          var isClickInside = menu.contains(event.target);

          if (!isClickInside) {
            this.openMenu = false;
            document.removeEventListener("click", detect);
          }
        };
        document.addEventListener("click", detect);
      }, 100);
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 8) {
        $event.preventDefault();
      }
    },
    async sendForm() {
      let data = {
        name: this.name,
        phone: this.phone,
      };
      await api.sendCallForm(data);

      this.name = "";
      this.phone = "";
    },
    async initCategories() {
      this.categoriesRes = await api.getAllCategories();
    },
  },
  async mounted() {
    await this.initCategories();
    this.targetCategories = this.categoriesRes.find(
      (el) => el.id == this.targetCatId
    ).items;
  },
};
</script>
<style lang="scss">
body .primary--text {
  color: var(--color) !important;
  caret-color: var(--color) !important;
}
.nav_main-phone--div {
  width: 170px;
  flex-wrap: wrap;
}
.nav {
  background: #fff;
  box-shadow: 0 3px 20px rgb(0 5 115 / 8%);
  margin-bottom: 30px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;

  .wrapper {
    width: 95%;
    margin: 10px auto;
  }

  &__main {
    display: flex;
    column-gap: 25px;
    flex-grow: 1;

    &-left {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;

      .nav-city {
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        height: 40px;
      }
    }

    &-right {
      display: flex;
      column-gap: 20px;
      row-gap: 10px;
      flex-grow: 1;
      flex-wrap: wrap;
      .nav__main-right--input {
        flex-grow: 1;
        display: flex;
        position: relative;
        height: 40px;
        background: var(--color-bg);
        border-radius: 4px;
        align-items: center;
      }
      .nav-search {
        outline: none;
        height: 40px;
        padding: 0 10px;
        border-radius: 4px;
        flex-grow: 1;
      }
      .nav-search-icon {
        margin-right: 10px;
        cursor: pointer;
      }

      .nav-icon-link {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #a4a4ad;
        img {
          height: 24px;
          width: 24px;
        }
      }
      .nav-icon-backet,
      .nav-icon-favotite {
        position: relative;

        i {
          position: absolute;
          min-width: 18px;
          min-height: 18px;
          font-size: 11px;
          line-height: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: var(--color);
          border-radius: 50%;
          color: #ffffff;

          top: -3px;
          right: 12px;
        }
      }
      .nav-icon-favotite {
        i {
          right: 18px;
        }
      }

      .nav__main-right--bottom {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: space-between;

        .nav__main-menu {
          display: flex;
          align-items: center;
          column-gap: 20px;

          &--items {
            display: inline-block;
            color: #000;

            &:hover {
              color: var(--color);
            }
          }
        }

        .nav__main-phone {
          display: flex;
          align-items: center;
          a {
            color: var(--color);
            font-weight: bold;
            font-size: 17px;
          }
        }
      }
    }
  }
  .nav__menu {
    position: fixed;
    top: 118px;
    background-color: #fff;
    box-shadow: 0 3px 20px rgb(0 5 115 / 8%);
    margin: 0;
    display: flex;
    padding: 20px;
    column-gap: 20px;
    height: 420px;

    &-left {
      display: flex;
      flex-direction: column;
      row-gap: 15px;
      padding-right: 20px;
      overflow: auto;
      max-width: 300px;

      &::-webkit-scrollbar {
        width: 7px; /* ширина всей полосы прокрутки */
      }
      &::-webkit-scrollbar-track {
        background: transparent; /* цвет зоны отслеживания */
      }

      &::-webkit-scrollbar-thumb {
        background-color: #e4e4e4; /* цвет бегунка */
        border-radius: 8px; /* округлось бегунка */
      }

      .nav__menu--category {
        h4 {
          background: #f0f2f1;
          border-radius: 4px;
          padding: 20px;
          width: 100%;
          flex-wrap: wrap;
        }
        img {
          margin-top: 5px;
          width: 100px;
        }
      }

      border-right: 2px solid #e2e2e2;

      h4 {
        cursor: pointer;
      }
    }

    &-right {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      padding: 20px;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 7px; /* ширина всей полосы прокрутки */
      }
      &::-webkit-scrollbar-track {
        background: transparent; /* цвет зоны отслеживания */
      }

      &::-webkit-scrollbar-thumb {
        background-color: #e4e4e4; /* цвет бегунка */
        border-radius: 8px; /* округлось бегунка */
      }

      a {
        color: #000;

        h6 {
          font-weight: normal;
        }
      }
    }
  }
}
.thanks-dialog {
  text-align: center;
}
.call-dialog {
  display: flex;
  position: relative;

  &.v-card > .v-card__text {
    padding: 50px 25px;
    color: #000;
  }

  h3 {
    margin-bottom: 20px;
  }

  .form {
    display: flex;
    flex-direction: column;

    label {
      font-size: 12px;
      margin-bottom: 8px;
    }

    input {
      background: #ffffff;
      border: 1px solid #e4e4e4;
      border-radius: 4px;
      padding: 12px 15px;
      outline: none;
      margin-bottom: 20px;
    }

    .btn {
      max-width: 150px;
    }

    b {
      color: red;
    }
  }

  .dialog-close {
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius: 50% !important;
    width: 32px;
    height: 32px;
    background: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.btn {
  background: var(--color);
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  padding: 0 30px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  height: 40px;

  &:hover {
    background: var(--color-hover);
  }
  &[small] {
    padding: 0 12px;
  }

  &:disabled {
    pointer-events: none;
    background: var(--color-hover);
    color: #ccc;
  }

  .mdi {
    color: #fff;
  }
}
.btn-invert {
  background: #fff;
  border: 1px solid var(--color);
  margin: 0 auto;
  color: var(--color);
  &:hover {
    color: #fff;
  }
}

.dialog_city {
  text-align: center;
  .thanks-text {
    display: flex;
    flex-direction: column;
    text-align: center;
    row-gap: 10px;

    h4 {
      display: inline-block;
      padding: 5px 10px;
      margin: 0 auto;
      border-radius: 4px;
      border: 2px solid var(--color);
      cursor: pointer;
    }
  }
}
.nav__menu-right {
  flex-grow: 1;
  position: relative;
}
.nav__menu-right-img {
  position: absolute;
  width: 150px;
  bottom: 0;
  right: 0;
}

.nav__main-mobile {
  width: 100%;
}
.nav__main-mobile--top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  > a img {
    height: 40px;
  }

  .btn,
  .btn[small] {
    padding: 0 10px !important;
  }
}
@media screen and (max-width: 960px) {
  .nav__main-right > .btn span {
    display: none;
  }
  .nav__main-right > .btn {
    padding-left: 10px;
    padding-right: 10px;

    img {
      margin-right: 0 !important;
    }
  }
  .nav .nav__menu {
    width: 100%;
    height: 100%;
    left: 0;
    top: 65px;
  }

  .nav .nav__menu-left {
    border-right: 0px;
  }
}
</style>
