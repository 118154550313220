<template>
  <footer class="footer">
    <v-dialog v-model="dialog" max-width="560">
      <v-card class="d-flex call-dialog">
        <a @click="dialog = false" class="dialog-close"
          ><img src="../assets/img/close.svg" alt=""
        /></a>
        <v-card-text>
          <h3>Обратный звонок</h3>
          <form class="form">
            <label for="call-name">Ваше имя <b color="red ">*</b></label>
            <input
              required
              id="call-name"
              type="text"
              placeholder="Введите имя"
              v-model="name"
            />
            <label for="call-phone">Телефон <b color="red ">*</b></label>
            <input
              required
              id="call-phone"
              type="tel"
              placeholder="Введите телефон"
              maxlength="11"
              @keydown="onlyNumber"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              v-model="phone"
            />
            <button
              :disabled="name == '' || phone == ''"
              @click="
                sendForm();
                dialog = false;
                dialog_thanks = true;
              "
              class="btn"
            >
              Отправить
            </button>
          </form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_thanks" max-width="560">
      <v-card class="d-flex call-dialog thanks-dialog">
        <a @click="dialog_thanks = false" class="dialog-close"
          ><img src="../assets/img/close.svg" alt=""
        /></a>
        <v-card-text>
          <h3>Спасибо!</h3>
          <div class="thanks-text">
            <p>
              Мы свяжемся с вами в ближайшее время <br />
              График работы: пн-пт, 9:00 - 18:00
            </p>
            <button @click="dialog_thanks = false" class="btn btn-invert">
              Вернуться к покупкам
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="wrapper">
      <v-row>
        <v-col class="col-12 col-md-3">
          <h3 class="footer-title">Контакты</h3>
          <ul class="footer__footer footer_links">
            <li class="footer__nav-item">
              <img src="../assets/img/phoneFooter.svg" alt="" />
              <a style="font-weight: bold" href="tel:+7(931)397-03-79">
               +7 (931)397-03-79</a
               >
            </li>
            <li class="footer__nav-item">
              <img src="../assets/img/email.svg" alt="" />info@izoprint.ru
            </li>
            <li class="footer__nav-item">
              <img src="../assets/img/mark.svg" alt="" />195221,
              Санкт-Петербург, <br />
              Кораблестроителей 32
            </li>
            <li class="footer__nav-item">
              <img src="../assets/img/clock.svg" alt="" />Пн-Пт: 9:00 — 18:00
            </li>
            <li class="footer__nav-item"></li>
          </ul>
        </v-col>
        <v-col class="col-12 col-md-3">
          <h3 class="footer-title">Каталог</h3>
          <ul class="footer__nav">
            <li class="footer__nav-item">
              <a href="/item?id=1">Магнитный винил</a>
            </li>
            <li class="footer__nav-item">
              <a href="/item?id=5">Магнитный винил с ПВХ</a>
            </li>
            <li class="footer__nav-item">
              <a href="/item?id=4">Ферропленка</a>
            </li>
            <li class="footer__nav-item">
              <a href="/item?id=7">Матовая пленка</a>
            </li>
            <li class="footer__nav-item">
              <a href="/item?id=6">Глянцевая пленка</a>
            </li>
            <li class="footer__nav-item">
              <a href="/item?id=8">Soft-Touch пленка</a>
            </li>
          </ul>
        </v-col>
        <v-col class="col-12 col-md-3">
          <h3 class="footer-title">Компания</h3>
          <ul class="footer__nav">
            <li class="footer__nav-item">
              <a href="/delivery">Оплата и Доставка</a>
            </li>
            <li class="footer__nav-item">
              <a href="/about">О компании</a>
            </li>

            <li class="footer__nav-item">
              <a download href="./inforamtion.txt">Реквизиты</a>
            </li>
            <li class="footer__nav-item"><a href="/contacts">Контакты</a></li>
          </ul>
        </v-col>
        <p class="footer_bottom mb-10 mb-md-0">
          2023 © Все права защищены. IZOPRINT 1999-2023 г. | Сайт не является
          публичной офертой и носит информационный характер.
        </p>
      </v-row>
    </div>

    <div class="footer__main-mobile d-block d-md-none">
      <div class="footer__main-mobile--bottom">
        <div class="footer-icon">
          <a
            class="footer-icon-link footer-icon-backet"
            @click="openMenu = !openMenu"
          >
            <img src="../assets/img/List.svg" alt="" />
            <span>Меню</span>
          </a>
        </div>

        <!-- <div class="footer-icon">
          <router-link
            to="/favorites"
            class="footer-icon-link footer-icon-favotite"
          >
            <img src="../assets/img/Heart.svg" alt="" />
            <span>Избранное</span>
            <i>{{ favorites_count }}</i>
          </router-link>
        </div> -->
        <div class="footer-icon">
          <router-link to="/backet" class="footer-icon-link footer-icon-backet">
            <img src="../assets/img/cart.svg" alt="" />
            <span>Корзина</span>
            <i>{{ backet_count }}</i>
          </router-link>
        </div>
      </div>
    </div>

    <div class="mobile_menu" v-if="openMenu">
      <div class="mobile_menu-wrapper">
        <!-- <a @click="openMenu = false" href="/sale" class="mobile_menu--items text">
                <img src="../assets/img/fire.svg" class="mr-1" alt="" />
                Акции</a
              > -->
        <a
          @click="openMenu = false"
          href="/catalog"
          class="mobile_menu--items text"
          >Каталог</a
        >
        <a
          @click="openMenu = false"
          href="/about"
          class="mobile_menu--items text"
          >О компании</a
        >
        <a
          @click="openMenu = false"
          href="/delivery"
          class="mobile_menu--items text"
          >Доставка</a
        >
        <a
          @click="openMenu = false"
          href="/contacts"
          class="mobile_menu--items text"
          >Контакты</a
        >
        <div class="mobile_menu-phone">
          <a href="tel:+7(931)397-03-79"> +7 (931)397-03-79</a>

          <button small class="btn" @click="dialog = true">
            <img src="../assets/img/phone.svg" class="mr-2" alt="" />
            <span> Заказать звонок</span>
          </button>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from "vuex";
import api from "../api";

export default {
  name: "Footer",
  computed: {
    ...mapGetters(["backet_count", "favorites_count"]),
  },
  data: () => ({
    dialog: false,
    dialog_thanks: false,
    openMenu: false,
    phone: "",
    name: "",
  }),
  methods: {
    async sendForm() {
      let data = {
        name: this.name,
        phone: this.phone,
      };
      await api.sendCallForm(data);
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 8) {
        $event.preventDefault();
      }
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
footer.footer {
  margin-top: 20px;
  background: #f5f5f5;
  padding: 40px 0;

  .footer-title {
    margin-bottom: 20px;
    font-weight: 500;
  }
  .footer_links {
    .footer__nav-item {
      margin-bottom: 20px;
    }
  }

  .footer__nav-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    img {
      margin-right: 12px;
    }

    a {
      color: #000;
    }
  }
  .footer_bottom {
    margin-top: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #8a8a8a;
  }
}
@media screen and (max-width: 1000px) {
  footer.footer .footer_links .footer__nav-item {
    font-size: 14px;
  }
}
.mobile_menu {
  position: fixed;
  top: 66px;
  bottom: 56px;
  background-color: #fff;
  z-index: 8;
  width: 100%;
  padding: 20px;

  &-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 25px;

    .mobile_menu--items {
      color: #000;
    }

    .mobile_menu-phone {
      display: flex;
      flex-direction: column;
      row-gap: 25px;

      a {
        color: #000;
      }
      .btn {
        margin-right: auto;
      }
    }
  }
}
.footer__main-mobile {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 3px 20px rgb(0 5 115 / 8%);
  z-index: 10;

  .footer__main-mobile--bottom {
    padding: 5px 0;
    display: flex;
    justify-content: space-evenly;

    .footer-icon a {
      img {
        width: 22px;
      }
      display: flex;
      align-items: center;
      flex-direction: column;
      span {
        color: #696969;
      }
    }
  }
  .footer-icon-backet,
  .footer-icon-favotite {
    position: relative;

    i {
      position: absolute;
      min-width: 18px;
      min-height: 18px;
      font-size: 11px;
      line-height: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--color);
      border-radius: 50%;
      color: #ffffff;

      top: -3px;
      right: 12px;
    }
  }
  .footer-icon-favotite {
    i {
      right: 18px;
    }
  }
}
</style>
