<template>
  <div class="favorites">
    <h1>Избранное</h1>
    <div
      class="home_hits-item product-item"
      v-for="item in favorites_items"
      :key="item.id"
    >
      <div class="item-panel">
        <div class="item-panel--wrapper">
          <span class="item-status--sale" v-if="item.status1 == 'sale'">
            <img src="../assets/img/fireRed.svg" class="mr-2" alt="" />
            Акция</span
          >
          <span
            class="item-status--new"
            v-if="item.status == 'new'"
            :style="item.status1 == 'sale' ? 'left: 100px' : 'left: 0px'"
            >Новинка</span
          >
        </div>

        <span class="item-favorites" @click="addFavoiteItem(item)"
          ><img
            v-if="item.favorites"
            src="../assets/img/heartFavActive.svg"
            alt="" /><img v-else src="../assets/img/heartFav.svg" alt=""
        /></span>
      </div>
      <img
        :src="
          'http://' + process.env.VUE_APP_ROOT_API + '/images/' + item.image
        "
        alt=""
      />
      <h5 class="item-title">
        <router-link :to="'/item?id=' + item.id">{{
          item.title +
          " " +
          item.wide +
          " мм" +
          " х " +
          item.length +
          " м, толщина " +
          item.thickness +
          " мм"
        }}</router-link>
      </h5>
      <p class="item-price">
        {{ item.sale_price ? item.sale_price : prettify(item.price) }} ₽
        <span class="item-sale-price" v-if="item.sale_price"
          >{{ item.price }} ₽</span
        >
      </p>
      <div class="item-buttons-wrapper">
        <button v-if="itemInBacket(item)" class="btn btn-invert">
          В корзине
        </button>
        <button
          v-else
          class="btn"
          @click="
            addItem(item);
            changeBtn($event);
          "
        >
          В корзину
        </button>
        <div class="item-count--wrapper">
          <v-icon class="item-count-icon" @click="itemMinusCount(item)"
            >mdi-minus</v-icon
          >
          <input
            @keypress="onlyNumber"
            type="text"
            class="item-count"
            v-model="item.count"
            @change="checkSale(item)"
          />
          <v-icon class="item-count-icon" @click="itemPlusCount(item)"
            >mdi-plus</v-icon
          >
        </div>
      </div>
      <!-- <button class="btn">Купить в 1 клик</button> -->

      <div class="item-bottom">
        <ul class="item-bottom-price">
          <li class="item-bottom-el" v-for="sale in item.sales" :key="sale.id">
            <span>от {{ sale.count }} шт. </span>
            <span>{{ sale.price }} ₽ / шт.</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Favorites",
  computed: {
    ...mapGetters(["favorites_items"]),
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    checkSale(item) {
      let breakFor = false;
      item.sale_price = item.price;

      if (item.sale_price == item.price) {
        item.sale_price = "";
      }

      item.sales.forEach((s) => {
        if (item.count >= s.count) {
          if (breakFor) return;
          item.sale_price = s.price;
          breakFor = true;
        }
      });
      this.totalPrice = this.count * this.price;
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 8) {
        $event.preventDefault();
      }
    },
    // функция для создания пробела между тысячами (1 000, 12 000)
    prettify(num) {
      var n = num.toString();
      var separator = " ";
      return n.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + separator);
    },
    addItem(item) {
      this.$store.dispatch("add_item_in_backet", item);
    },
    itemInBacket(item) {
      let items = JSON.parse(localStorage.getItem("items"));
      if (items) {
        if (items.find((el) => el.id == item.id)) {
          return true;
        }
      }
      return false;
    },
    itemPlusCount(item) {
      if (this.itemInBacket(item)) {
        this.$store.dispatch("item_plus_count", item);
        let items = JSON.parse(localStorage.getItem("items"));
        item.count = items.find((el) => el.id == item.id).count;
      } else {
        if (item.count <= 1) {
          item.count = 1;
        }
        item.count = item.count + 1;
      }
      this.checkSale(item);
    },
    itemMinusCount(item) {
      if (this.itemInBacket(item)) {
        if (item.count <= 1) {
          item.count = 1;
        } else {
          this.$store.dispatch("item_minus_count", item);
          let items = JSON.parse(localStorage.getItem("items"));
          item.count = items.find((el) => el.id == item.id).count;
        }
      } else {
        if (item.count <= 1) {
          item.count = 1;
        } else {
          item.count = item.count - 1;
        }
      }
      this.checkSale(item);
    },
    changeBtn(e) {
      let elem = e.srcElement;
      elem.innerHTML = "В корзине";
      elem.style.pointerEvents = "none";
      elem.classList.add("btn-invert");
    },
    addFavoiteItem(item) {
      this.$store.dispatch("toogle_favorites_item", item);
      item.favorites = !item.favorites;
    },
    checkFavorites(item) {
      let items = JSON.parse(localStorage.getItem("favorites-items"));
      if (items.find((el) => el.id == item.id)) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.favorites_items.forEach((i) => {
      console.log(i);
      if (this.itemInBacket(i)) {
        let items = JSON.parse(localStorage.getItem("items"));
        i.count = items.find((el) => el.id == i.id).count;
        this.checkSale(i);
      }
    });
  },
  components: {},
};
</script>
<style lang="scss">
.favorites {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  h1 {
    width: 100%;
  }
}
@media screen and (max-width: 960px) {
  .favorites {
    .product-item {
      width: 45%;
    }
  }
}
@media screen and (max-width: 600px) {
  .favorites {
    .product-item {
      width: 100%;
    }
  }
}
</style>
