import axios from "axios";
const client = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  json: true,
});

export default {
  async execute(method, resourse, data) {
    let reqObj = {
      method,
      url: resourse,
      data,
    };

    reqObj["headers"] = {
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    return client(reqObj).then(
      (req) => {
        return req.data;
      },
      (err) => {
        console.error(err);
      }
    );
  },
  async getAllCategories() {
    return await this.execute("GET", "categories");
  },
  async getCategory(id) {
    return await this.execute("GET", "categories/" + id);
  },
  async getAllItems() {
    return await this.execute("GET", "items/");
  },
  async getLimitsItems(skip, limit) {
    return await this.execute("GET", "items/?skip=" + skip + "&limit=" + limit);
  },
  async getItemsByCategory(id) {
    return await this.execute("GET", "/items_by_category/" + id);
  },
  async getLimitsItemsByCategory(id, skip, limit) {
    return await this.execute(
      "GET",
      "items_by_category/" + id + "?skip=" + skip + "&limit=" + limit
    );
  },
  async getItemsBySubCategory(id) {
    return await this.execute("GET", "/items_by_subcategory/" + id);
  },
  async getLimitsItemsBySubCategory(id, skip, limit) {
    return await this.execute(
      "GET",
      "items_by_subcategory/" + id + "?skip=" + skip + "&limit=" + limit
    );
  },
  async getItemById(id) {
    return await this.execute("GET", "/items/" + id);
  },
  async sendForm(data) {
    return await this.execute("POST", "/orders", data);
  },
  async sendCallForm(data) {
    return await this.execute("POST", "/callrequest", data);
  },
  async getAdditional() {
    return await this.execute("GET", "/additionals");
  },
  async search(data) {
    return await this.execute("GET", "/search/?query=" + data);
  },
  async getBanners() {
    return await this.execute("GET", "/banners");
  },
};
