<template>
  <div class="category">
    <div class="breadcrumbs">
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    </div>
    <h1>{{ page_title }}</h1>

    <div class="category__panels">
      <vue-horizontal responsive>
        <a
          :href="'/item?id=' + item.id"
          v-for="item in sub_cateogrys"
          :key="item.id"
        >
          <div class="category__panels-item">
            <img :src="url + 'images/' + item.image" alt="" />
            <h2>{{ item.name }}</h2>
          </div>
        </a>
      </vue-horizontal>
    </div>
    <div class="category__wrapper">
      <div class="category__items">
        <a
          class="category__item"
          v-for="item in sub_cateogrys"
          :key="item.id"
          :href="'/item?id=' + item.id"
        >
          <img :src="url + 'images/' + item.image" alt="" />
          <h3>{{ item.name }}</h3>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// import MultiRangeSlider from "multi-range-slider-vue";
import "../../node_modules/multi-range-slider-vue/MultiRangeSliderBarOnly.css";
import { mapGetters } from "vuex";
import VueHorizontal from "vue-horizontal";
import router from "../router";
import api from "../api";

export default {
  name: "Category",
  computed: {
    ...mapGetters(["page_title"]),
  },
  data: () => ({
    url: process.env.VUE_APP_ROOT_API,
    active: true,
    availability: false,
    category_id: router.history.current.query.id,
    subcategory_id: router.history.current.query.sub,
    breadcrumbs: [
      {
        text: "Главная",
        disabled: false,
        href: "/",
      },
      {
        text: "Каталог",
        disabled: false,
        href: "/catalog",
      },
    ],
    sub_cateogrys: [],
    items: [],
    activeFilter: false,
    search: router.history.current.query.search,
  }),
  components: {
    VueHorizontal,
    // MultiRangeSlider,
  },
  watch: {
    search() {
      this.initItems();
    },
  },
  methods: {
    closeFilters() {
      let filters = document.querySelector(".filters");

      if (filters.classList.contains("active")) {
        filters.classList.remove("active");
      }
    },
    async initItems() {
      let res;
      if (this.search) {
        res = await api.search(this.search);
      } else if (this.subcategory_id) {
        res = await api.getItemsBySubCategory(this.subcategory_id);
      } else if (this.category_id) {
        res = await api.getItemsByCategory(this.category_id);
      }
      console.log(res);

      this.sub_cateogrys = res;

      this.sub_cateogrys.forEach((i) => {
        if (i.image[0]) {
          i.image = i.image[0].name;
        }
      });
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 8) {
        $event.preventDefault();
      }
    },
    // функция для создания пробела между тысячами (1 000, 12 000)
    prettify(num) {
      var n = num.toString();
      var separator = " ";
      return n.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + separator);
    },
    addItem(item) {
      this.$store.dispatch("add_item_in_backet", item);
    },
    itemInBacket(item) {
      let items = JSON.parse(localStorage.getItem("items"));
      if (items) {
        if (items.find((el) => el.id == item.id)) {
          return true;
        }
      }
      return false;
    },
    itemPlusCount(item) {
      if (this.itemInBacket(item)) {
        this.$store.dispatch("item_plus_count", item);
        let items = JSON.parse(localStorage.getItem("items"));
        item.count = items.find((el) => el.id == item.id).count;
      } else {
        if (item.count <= 1) {
          item.count = 1;
        }
        item.count = item.count + 1;
      }
      this.checkSale(item);
    },
    itemMinusCount(item) {
      if (this.itemInBacket(item)) {
        if (item.count <= 1) {
          item.count = 1;
        } else {
          this.$store.dispatch("item_minus_count", item);
          let items = JSON.parse(localStorage.getItem("items"));
          item.count = items.find((el) => el.id == item.id).count;
        }
      } else {
        if (item.count <= 1) {
          item.count = 1;
        } else {
          item.count = item.count - 1;
        }
      }
      this.checkSale(item);
    },
    changeBtn(e) {
      let elem = e.srcElement;
      elem.innerHTML = "В корзине";
      elem.style.pointerEvents = "none";
      elem.classList.add("btn-invert");
    },
    async initBreadCrumbs() {
      if (router.history.current.query.search) {
        console.log("search");
      } else if (this.category_id) {
        let res = await api.getCategory(router.history.current.query.id);

        if (res) {
          this.sub_cateogrys = res.items;

          this.sub_cateogrys.forEach((i) => {
            if (i.image[0]) {
              i.image = i.image[0].name;
            }
          });
          this.$store.dispatch("set_title", res.name);

          this.breadcrumbs.push({
            text: res.name, // получать название катеогрии
            disabled: false,
            href: "/category?id=" + res.id,
          });

          if (this.subcategory_id) {
            let sub = res.subcategories.find(
              (i) => i.id == this.subcategory_id
            );

            this.$store.dispatch("set_title", sub.name);

            this.breadcrumbs.push({
              text: sub.name, // получать название подкатегории
              disabled: false,
              href: "",
            });
          }
        }
      } else {
        router.push("/catalog");
      }
    },
    addFavoiteItem(item) {
      this.$store.dispatch("toogle_favorites_item", item);
      item.favorites = !item.favorites;
    },
    checkFavorites(item) {
      let items = JSON.parse(localStorage.getItem("favorites-items"));
      if (items)
        if (items.find((el) => el.id == item.id)) {
          return true;
        }
      return false;
    },
  },
  mounted() {
    this.initBreadCrumbs();
    this.initItems();
  },
};
</script>
<style lang="scss">
.category {
  &__wrapper {
    display: flex;
    column-gap: 40px;
    margin-top: 30px;

    .filters {
      display: none;
    }
  }

  .v-hl-container {
    padding-left: 7px !important;
    padding-right: 7px !important;
  }

  .category__panels-item {
    margin: 10px 0;
    padding: 15px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 15px;
    height: 111px;

    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid transparent;

    &:hover {
      border: 1px solid var(--color);
    }

    h2 {
      font-size: 15px;
      line-height: 20px;
      color: #000;
    }

    img {
      width: 70px;
      height: 70px;
      object-fit: contain;
    }
  }

  &__items {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .category__items-panel {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .btn-filters {
        margin: 0;
      }
    }

    .category__item {
      width: 32%;
      padding: 20px;
      text-align: center;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.08);

      color: #000;

      img {
        height: 200px;
        object-fit: contain;
        margin-bottom: 20px;
      }
    }
  }
}
.product-item {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 30%;
  padding: 10px;
  border-radius: 4px;
  transition: 0.3s;

  &:hover {
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.08);

    .item-title {
      a {
        color: var(--color);
      }
    }
  }

  .item-title {
    a {
      color: #000;
    }
    height: 100px;
  }

  .btn {
    padding: 0 8px;
  }

  .item-bottom {
    margin-top: 10px;
    .item-bottom-price {
      display: flex;
      flex-direction: column;
      row-gap: 3px;
      .item-bottom-el {
        padding: 0px 8px;
        display: flex;
        justify-content: space-between;
        border: 1px solid #e4e4e4;
        border-radius: 4px;
        color: #969696;
      }
    }
  }

  .item-panel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    height: 30px;
    column-gap: 5px;

    &--wrapper {
      display: flex;
      column-gap: 10px;
    }
  }

  > img {
    width: 100%;
    object-fit: contain;
  }

  .item-status--new {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-family: "Montserrat";
    border: 2px solid var(--color);
    font-weight: bold;
    color: var(--color);
    text-transform: uppercase;
    padding: 4px 6px;
  }

  .item-favorites {
    cursor: pointer;
    display: none;
    align-items: center;

    img {
      height: 24px;
    }
  }

  .item-status--sale {
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Montserrat";
    top: 0;
    left: 0;
    border: 2px solid #ffce4c;
    background: #ffce4c;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    padding: 4px 10px;

    img {
      width: 14px;
    }
  }

  .item-price {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-size: 16px;
    margin-top: 15px;
    font-weight: bold;
  }

  .item-sale-price {
    position: relative;
    color: #1b2339;
    font-size: 14px;
    opacity: 0.6;

    &::after {
      content: "";
      background: #eb5757;
      bottom: 50%;
      height: 2px;
      left: 0;
      position: absolute;
      -webkit-transform: skewY(-12deg);
      transform: skewY(-12deg);
      width: 100%;
    }
  }

  .item-buttons-wrapper {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 5px;

    .btn {
      flex-grow: 1;
    }

    .item-count--wrapper {
      display: flex;
      justify-content: center;
      column-gap: 5px;
      border: 2px solid #e4e4e4;
      border-radius: 4px;
      height: 40px;
      flex-grow: 1;

      input {
        text-align: center;
        width: 40px;
        outline: none;
      }

      .item-count-icon {
        cursor: pointer;

        &::after {
          display: none;
        }
      }
    }
  }
}
.disable {
  pointer-events: none;
}

@media screen and (max-width: 1265px) {
  .wrapper {
    width: 90%;
  }
}

@media screen and (max-width: 1130px) {
  .category__items .category__item {
    width: 48%;
  }
}

@media screen and (max-width: 960px) {
  .category__wrapper .filters {
    display: none;
    position: fixed;
    overflow: auto;
    z-index: 2;
    background-color: #fff;
    width: 100%;
    top: 76px;
    left: 0;
    height: calc(100% - 122px);
    padding: 20px;

    &.active {
      display: block;
    }
  }
  .product-item .item-title {
    height: 130px;
  }
}
@media screen and (max-width: 600px) {
  .category__items .category__item img {
    height: 100px;
  }
  .category__items {
    gap: 10px;
  }
  .category__item h3 {
    font-size: 18px;
    line-height: 24px;
  }
  .product-item {
    width: 46%;
  }
  .product-item .item-title {
    height: 150px;
    font-size: 16px;
  }
  .product-item .item-bottom .item-bottom-price .item-bottom-el {
    font-size: 12px;
  }
  .btn {
    font-size: 14px;
  }
  .product-item .item-panel {
    height: 60px;
    align-items: start;
  }
  .product-item .item-panel--wrapper {
    flex-wrap: wrap;
    row-gap: 10px;
  }
}

.filters {
  .multi-range-slider-bar-only {
    padding: 0;
    .bar {
      height: 4px;
    }

    .bar-inner {
      box-shadow: none;
      background: var(--color);
    }

    .bar-left,
    .bar-right {
      padding: 0;
      background: #fff;
      box-shadow: none;
      border: 1px solid var(--color);
    }
    .thumb {
      .caption {
        display: none;
      }

      &-left {
        transform: translateX(-8px);
      }
      &-right {
        transform: translateX(-8px);
      }
      &::before {
        width: 16px;
        height: 16px;
        border-width: 0;
        background: #f9fafb;
        box-shadow: 0px 1px 2px rgba(145, 158, 171, 0.7);
        margin: 0;
        top: -6px;
      }
    }
  }

  .filters_top {
    display: flex;
    column-gap: 10px;
    align-items: center;
    a {
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 5px;
      color: #afafaf;
    }
    .btn-filters {
      margin-right: 0;
      margin-left: auto;
    }
  }

  .v-input--checkbox {
    .primary--text {
      color: var(--color) !important;
    }
    .v-input--selection-controls__ripple {
      display: none;
      &::after,
      &::before {
        content: none;
      }
    }
  }

  .filters_price-inputs {
    display: flex;
    margin-top: 15px;
    margin-bottom: 20px;
    justify-content: space-between;
    column-gap: 10px;

    .filters_price-label {
      display: flex;
      column-gap: 10px;
      width: 100%;

      background: #ffffff;
      border: 1px solid #e4e4e4;
      border-radius: 4px;
      padding: 10px;

      input {
        outline: none;
        width: 50px;
        text-align: center;
      }
    }
  }

  .filters_categories {
    height: 100%;
    overflow: auto;
    margin-top: 25px;

    .filters_categories-item {
      margin-bottom: 25px;

      .v-input--selection-controls {
        margin-top: 5px;
      }
    }
  }
}

.multi-range-slider-bar-only {
  width: 90%;
  margin: 0 auto;
}
</style>
