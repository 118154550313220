<template>
  <div class="pageItem">
    <v-dialog v-model="dialog_imgs" max-width="700">
      <v-card class="d-flex call-dialog thanks-dialog images-dialog">
        <a @click="dialog_imgs = false" class="dialog-close"
          ><img src="../assets/img/close.svg" alt=""
        /></a>
        <v-card-text>
          <img
            @click="prevImg"
            class="leftArrow"
            src="@/assets/img/leftArrow.svg"
          />
          <img
            style="width: 100%; height: 100%"
            :src="url + 'images/' + targetImage"
          />
          <img
            @click="nextImg"
            class="rightArrow"
            src="@/assets/img/leftArrow.svg"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <div class="breadcrumbs">
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    </div>
    <div class="category__panels">
      <vue-horizontal responsive>
        <a
          :href="'/item?id=' + item.id"
          v-for="item in sub_cateogrys"
          :key="item.id"
        >
          <div class="category__panels-item">
            <img :src="url + 'images/' + item.image" alt="" />
            <h2>{{ item.name }}</h2>
          </div>
        </a>
      </vue-horizontal>
    </div>
    <h1>
      {{ title }}
    </h1>
    <div class="pageItem-panel">
      <!-- <span class="pageItem-panel--favorites"
        ><img
          v-if="itemFavorites"
          src="../assets/img/heartFavActive.svg"
          alt=""
        /><img v-else src="../assets/img/heartFav.svg" alt="" />
        В избранное
      </span> -->
      <!-- <span class="pageItem-panel--share"
        ><img src="../assets/img/Share.svg" alt="" />
        Поделиться
      </span> -->
    </div>
    <div class="pageItem-wrapper">
      <div class="pageItem-left">
        <div class="pageItem-photos">
          <div class="pageItem-mainPhoto">
            <img
              @click="dialog_imgs = true"
              :src="url + 'images/' + targetImage"
              alt=""
            />
          </div>
          <div class="pageItem-galery">
            <div
              class="pageItem-galery--item"
              v-for="item in images"
              :key="item.id"
              :class="item.name == targetImage ? 'galery-active' : ''"
            >
              <img
                @click="targetImage = item.name"
                :src="url + 'images/' + item.name"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="pageItem-info">
          <div class="description">
            <p>
              {{ description }}
            </p>
          </div>
          <div class="pageItem-characteristics">
            <div
              class="pageItem-characteristics--item"
              v-for="item in itemChar"
              :key="item.id"
            >
              <h5>{{ item.name }}</h5>
              <h6>{{ item.value }}</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="pageItem-right">
        <div class="pageItem-actions">
          <div class="pageItem-hasIs">
            <img v-if="hasIs" src="../assets/img/tick.svg" />
            <img v-else src="../assets/img/tick_grey.svg" />
            <span v-if="hasIs">В наличии</span>
            <span v-else>Отсутсвует</span>
          </div>
          <div class="pageItem-variable">
            <h6 v-if="additionalInfo" style="width: 100%">
              {{ additionalInfoName }}
            </h6>
            <div v-if="additionalInfo" class="pageItem-variable-buttons">
              <button
                :disabled="additionalInfoDisabled"
                class="btn btn-invert"
                :active="additionalValue == additionalInfoArr[0]"
                @click="additionalValue = additionalInfoArr[0]"
              >
                {{ additionalInfoArr[0] }}
              </button>
              <button
                :disabled="additionalInfoDisabled"
                class="btn btn-invert"
                :active="additionalValue == additionalInfoArr[1]"
                @click="additionalValue = additionalInfoArr[1]"
              >
                {{ additionalInfoArr[1] }}
              </button>
            </div>
            <v-select
              style="width: 100%"
              v-for="elem in hugeArr.filter((el) => el.values.length > 1)"
              :key="elem.name"
              :label="elem.name + ' (' + elem.unit + ')'"
              outlined
              :items="elem.values"
              v-model="changeArr.find((el) => el.name == elem.name).value"
              dense
              :disabled="elem.disabled"
              @change="changeParam(elem.name)"
            />

            <div class="pageItem-variable--count">
              <div class="item-count--wrapper">
                <v-icon class="item-count-icon" @click="itemMinusCount"
                  >mdi-minus</v-icon
                >
                <input
                  @keypress="onlyNumber"
                  type="text"
                  class="item-count"
                  v-model="count"
                />
                <v-icon class="item-count-icon" @click="itemPlusCount"
                  >mdi-plus</v-icon
                >
              </div>

              <div class="pageItem-variable--price" v-if="target_attribute">
                <h2>
                  <span>Итого </span>
                  <b>{{ prettify(totalPrice) }}<sup>*</sup> </b> ₽
                </h2>
                <h3>цена за {{ count }} шт * {{ price }} руб.</h3>
              </div>

              <div class="pageItem-variable--backet">
                <button
                  class="btn flex-grow-1"
                  @click="addItem()"
                  :disabled="target_attribute == null"
                >
                  <!-- changeBtn($event); -->
                  <img src="../assets/img/cartW.svg" alt="" class="mr-2" /> В
                  корзину
                </button>
                <span class="backet-dopInfo mt-1"
                  >* возможно изменение цены в зависимости от курса, корректную
                  цену на момент заказа сообщит менеджер</span
                >

                <div class="pageItem-variable--salePrice">
                  <ul class="item-bottom-price">
                    <li
                      class="item-bottom-el"
                      v-for="sale in sales"
                      :key="sale.id"
                    >
                      <span>от {{ sale.count }} шт. </span>
                      <span>{{ sale.price }} ₽ / шт.</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pageItem-delivery">
          <h3>Доставка</h3>

          <div class="pageItem-delivery--item">
            <img src="../assets/img/time.svg" alt="" />
            <h6>
              Бесплатная доставка по Москве в пределах МКАД при заказе от 40
              тыс.рублей
            </h6>
          </div>
          <div class="pageItem-delivery--item mt-5">
            <h6>
              Бесплатная доставка по СПБ (в пределах 20 км) при заказе от 10
              тыс.рублей
            </h6>
          </div>
          <div class="pageItem-delivery--item mt-5">
            <h6 class="mb-4">Отправка транспортными компаниями:</h6>
            <h6 class="logotips">
              Байкал сервис <img src="../assets/img/baikal.png" alt="" />
            </h6>
            <h6 class="logotips">
              ПЭК <img src="../assets/img/pek.png" alt="" />
            </h6>
            <h6 class="logotips">
              СДЭК <img src="../assets/img/cdek.png" alt="" />
            </h6>
            <h6 class="logotips">
              Деловые ЛИНИИ
              <img src="../assets/img/business_lines.png" alt="" />
            </h6>
          </div>
          <p class="mt-5">
            Подробнее смотрите на странице
            <router-link to="/delivery"> доставки</router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "../router";
import api from "../api";
import VueHorizontal from "vue-horizontal";

export default {
  name: "Item",
  watch: {
    count() {
      this.checkSale();
    },
    additionalValue() {
      // this.setAdditional();
      if (this.hugeArr.find((el) => el.values.length > 1)) {
        this.hugeArr.find((el) => el.values.length > 1).disabled = false;
        this.changeArr.forEach((el) => {
          el.value = null;
        });
        this.resetData();
      } else {
        this.setAdditional();
        return;
      }
    },
  },
  data: () => ({
    target_attribute: null,
    target_length: null,
    target_wide: null,
    target_thickness: null,
    url: process.env.VUE_APP_ROOT_API,
    count: 1,
    hasIs: true,
    haveClue: false,
    initPrice: 0,
    price: 0,
    totalPrice: 0,
    sales: [],
    title: "",
    description: "",
    thickness: "",
    wide: "",
    length: "",
    itemFavorites: false,
    targetImage: "",
    tab: "",
    images: [],
    itemChar: [],
    category_id: 0,
    item_id: router.history.current.query.id,
    dopItems: [],
    dlina: [],
    thickness_arr: [],
    wide_arr: [],
    length_arr: [],
    breadcrumbs: [
      {
        text: "Главная",
        disabled: false,
        href: "/",
      },
      {
        text: "Каталог",
        disabled: false,
        href: "/catalog",
      },
    ],
    sub_cateogrys: [],
    attributes: [],
    additionalInfo: false,
    additionalInfoArr: [],
    additionalValue: "",
    hugeArr: [],
    hugeArrInit: [],
    changeArr: [],
    lastIndex: null,
    additionalInfoDisabled: false,
    additionalInfoName: "",
    dialog_imgs: false,
  }),
  methods: {
    changeParam(name) {
      this.resetData();
      // this.additionalInfoDisabled = true;
      // this.additionalValue = "";

      let countFull = 0;

      this.hugeArrInit.forEach((el) => {
        if (el.values.length == 1) {
          countFull++;
          this.changeArr.find((i) => i.name == el.name).value = el.values[0];
        }
      });

      if (countFull == this.hugeArrInit.length) {
        let fFilter = this.changeArr[0];
        let atrs = this.attributes.filter(
          (el) =>
            el.param.find((i) => i.name == fFilter.name).value == fFilter.value
        );
        if (atrs.length > 1) {
          this.additionalInfoDisabled = false;
          return;
        }
        this.target_attribute = atrs[0];
        this.changeData();
        return;
      }

      let index = this.hugeArr.indexOf(
        this.hugeArr.find((el) => el.name == name)
      );

      for (let i = index + 1; i < this.changeArr.length; i++) {
        this.changeArr[i].value = null;
        this.hugeArr[i].disabled = true;
      }

      this.lastIndex = index;

      let fFilter = this.changeArr[0];
      let atrs = this.attributes.filter(
        (el) =>
          el.param.find((i) => i.name == fFilter.name).value == fFilter.value
      );

      this.changeArr.forEach((change) => {
        if (change.value) {
          atrs = atrs.filter(
            (el) =>
              el.param.find((i) => i.name == change.name).value == change.value
          );

          for (let i = index + 1; i < this.hugeArr.length; i++) {
            this.hugeArr[i].disabled = false;
            this.hugeArr[i].values = [];
            atrs.forEach((el) => {
              let atr = el.param.find((e) => e.name == this.hugeArr[i].name);
              this.hugeArr[i].values.push(atr.value);
            });
          }
        }
      });

      if (atrs.length == 1) {
        atrs[0].param.forEach((el) => {
          if (el.value)
            this.changeArr.find((i) => i.name == el.name).value = el.value;
        });
      } else {
        if (index + 1 !== this.hugeArr.length) {
          if (this.hugeArr[index + 1].values.length == 1) {
            if (this.hugeArr[index + 2]) {
              this.hugeArr[index + 2].disabled = false;
              this.hugeArr[index + 2].values = [];
              atrs.forEach((el) => {
                let atr = el.param.find(
                  (i) => i.name == this.hugeArr[index + 2].name
                );
                this.hugeArr[index + 2].values.push(atr.value);
              });
            }
          } else {
            this.hugeArr[index + 1].disabled = false;
            this.hugeArr[index + 1].values = [];
            atrs.forEach((el) => {
              let atr = el.param.find(
                (i) => i.name == this.hugeArr[index + 1].name
              );
              this.hugeArr[index + 1].values.push(atr.value);
            });
          }
          this.hugeArr.forEach((el) => {
            el.values = [...new Set(el.values)];
          });

          for (let i = index + 1; i < this.hugeArr.length; i++) {
            if (this.hugeArr[i].values.length == 1) {
              this.changeArr[i].value = this.hugeArr[i].values[0];
            }
          }
        }
      }

      let fullIs = false;

      for (let i = 0; i < this.changeArr.length; i++) {
        if (!this.changeArr[i].value) {
          fullIs = false;
          break;
        } else {
          fullIs = true;
        }
      }
      if (fullIs) {
        if (!this.additionalInfo) {
          this.target_attribute = atrs[0];
          this.changeData();
        } else {
          this.setAdditional();
        }
      }
      if (this.item_id == 9) {
        if (this.hugeArr.find((el) => el.name == "Втулка").values[0])
          this.hugeArr.find((el) => el.name == "Втулка").values =
            this.hugeArr.find((el) => el.name == "Втулка").values[0];
        else
          this.hugeArr.find((el) => el.name == "Втулка").values =
            this.hugeArr.find((el) => el.name == "Втулка").values;
      }
    },
    setAdditional() {
      if (this.additionalValue) {
        let fFilter = this.changeArr[0];
        let atrs = this.attributes.filter(
          (el) =>
            el.param.find((i) => i.name == fFilter.name).value == fFilter.value
        );

        this.changeArr.forEach((change) => {
          if (change.value) {
            atrs = atrs.filter(
              (el) =>
                el.param.find((i) => i.name == change.name).value ==
                change.value
            );
          }
        });

        this.target_attribute = atrs.find(
          (el) =>
            el.param.find((i) => i.name == this.additionalInfoName)
              .additional == this.additionalValue
        );
        this.changeData();
      }
    },
    changeData() {
      if (this.target_attribute) {
        this.itemChar = [];
        this.target_attribute.param.forEach((el) => {
          if (el.value)
            this.itemChar.push({
              name: el.name,
              value: el.value + " " + el.unit,
            });
        });
        this.sales = [];
        if (this.target_attribute.offer)
          this.target_attribute.offer.forEach((el) => {
            this.sales.push({
              id: el.id,
              count: el.offer,
              price: el.offer_price,
            });
          });

        this.initPrice = this.target_attribute.price;
        this.checkSale();
      } else {
        this.resetData();
      }
    },
    resetData() {
      if (this.item_id == 1) {
        if (this.hugeArr.find((el) => el.name == "Длина").values[0])
          this.hugeArr.find((el) => el.name == "Длина").values =
            this.hugeArr.find((el) => el.name == "Длина").values[0];
        else
          this.hugeArr.find((el) => el.name == "Длина").values =
            this.hugeArr.find((el) => el.name == "Длина").values;
      }
      if (this.item_id == 9) {
        if (this.hugeArr.find((el) => el.name == "Втулка").values[0])
          this.hugeArr.find((el) => el.name == "Втулка").values =
            this.hugeArr.find((el) => el.name == "Втулка").values[0];
        else
          this.hugeArr.find((el) => el.name == "Втулка").values =
            this.hugeArr.find((el) => el.name == "Втулка").values;
      }
      // this.additionalValue = "";
      this.target_attribute = null;
      this.itemChar = [];
      this.hugeArrInit.forEach((el) => {
        if (el.values.length == 1) {
          this.itemChar.push({
            name: el.name,
            value: el.values[0] + ", " + el.unit,
          });
        }
      });
      this.sales = [];
      this.initPrice = 0;
      this.checkSale();
    },
    async initItem() {
      let res = await api.getItemById(this.item_id);
      this.title = res.name;
      this.description = res.description;
      this.images = res.image;
      this.targetImage = res.image[0].name;
      this.category_id = res.category_id;
      this.initBreadCrumbs();

      this.attributes = res.attribute;

      this.attributes.forEach((a) => {
        a.param.forEach((el) => {
          if (this.hugeArrInit.find((i) => i.name == el.name)) {
            this.hugeArrInit
              .find((i) => i.name == el.name)
              .values.push(el.value);
          } else {
            if (el.value) {
              let item = {
                name: el.name,
                unit: el.unit,
                values: [el.value],
                disabled: true,
              };
              this.hugeArrInit.push(item);
            }
            if (el.additional) {
              this.additionalInfo = true;
              this.additionalInfoName = el.name;
              this.additionalInfoArr.push(el.additional);
            }
          }
        });
      });

      this.hugeArrInit.forEach((el) => {
        el.values = [...new Set(el.values)];
      });

      if (this.additionalInfoArr) {
        this.additionalInfoArr = [...new Set(this.additionalInfoArr)];
      }

      this.hugeArr = JSON.parse(JSON.stringify(this.hugeArrInit));
      if (this.hugeArr.find((el) => el.values.length > 1)) {
        if (!this.additionalInfo) {
          this.hugeArr.find((el) => el.values.length > 1).disabled = false;
        }
      } else {
        this.hugeArr.forEach((el) => {
          this.changeArr.push({
            name: el.name,
            value: null,
          });
        });
        this.changeParam();
        this.checkSale();

        return;
      }

      this.hugeArr.forEach((el) => {
        this.changeArr.push({
          name: el.name,
          value: null,
        });
      });

      this.resetData();

      this.checkSale();
    },
    checkSale() {
      let breakFor = false;
      this.price = this.initPrice;
      if (this.sales) {
        for (var i = this.sales.length - 1; i >= 0; i--) {
          let s = this.sales[i];
          if (this.count >= s.count) {
            if (breakFor) return;
            this.price = s.price;

            this.totalPrice = this.count * this.price;
            breakFor = true;
          }
        }
      }
      this.totalPrice = this.count * this.price;
    },
    itemPlusCount() {
      if (this.count <= 1) {
        this.count = 1;
      }
      this.count = this.count + 1;
    },
    itemMinusCount() {
      if (this.count <= 1) {
        this.count = 1;
      } else {
        this.count = this.count - 1;
      }
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 8) {
        $event.preventDefault();
      }
    },
    prettify(num) {
      var n = num.toString();
      var separator = " ";
      return n.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + separator);
    },
    addItem() {
      let target = this.attributes.find(
        (el) => el.id == this.target_attribute.id
      );
      let item = {
        attribute: target,
        title: this.title,
        id: this.item_id + "-" + target.id,
        category_id: this.category_id,
        image: this.targetImage,
        wide: target.wide,
        price: this.initPrice,
        sale_price: this.price,
        sales: this.sales,
        description: this.description,
        length: target.length,
        thickness: target.thickness,
        count: this.count,
      };
      this.$store.dispatch("add_item_in_backet", item);
    },
    async initBreadCrumbs() {
      if (this.category_id) {
        let res = await api.getCategory(this.category_id);

        if (res) {
          this.sub_cateogrys = res.items;

          this.sub_cateogrys.forEach((i) => {
            if (i.image[0]) {
              i.image = i.image[0].name;
            }
          });

          this.breadcrumbs.push({
            text: res.name,
            disabled: false,
            href: "/category?id=" + res.id,
          });
        }
      } else {
        router.push("/catalog");
      }
    },
    addFavoiteItem(item) {
      this.$store.dispatch("toogle_favorites_item", item);
      item.favorites = !item.favorites;
    },
    nextImg() {
      let img = this.images.find((item) => item.name == this.targetImage);
      let index = this.images.indexOf(img);

      if (this.images[index + 1] !== undefined)
        this.targetImage = this.images[index + 1].name;
      else this.targetImage = this.images[0].name;
    },
    prevImg() {
      let img = this.images.find((item) => item.name == this.targetImage);
      let index = this.images.indexOf(img);

      if (this.images[index - 1] !== undefined)
        this.targetImage = this.images[index - 1].name;
      else this.targetImage = this.images[this.images.length - 1].name;
    },
  },
  mounted() {
    this.initItem();
  },
  components: { VueHorizontal },
};
</script>
<style lang="scss">
.images-dialog {
  position: relative;
  .rightArrow,
  .leftArrow {
    position: absolute;
    width: 25px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .leftArrow {
    left: 5px;
    transform: translateY(-50%) rotate(180deg);
  }
  .rightArrow {
    right: 5px;
  }
}

.pageItem {
  .v-hl-container {
    padding-left: 7px !important;
    padding-right: 7px !important;
  }

  .category__panels-item {
    margin: 10px 0;
    padding: 15px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 15px;
    height: 111px;

    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid transparent;

    &:hover {
      border: 1px solid var(--color);
    }

    h2 {
      font-size: 15px;
      line-height: 20px;
      color: #000;
    }

    img {
      width: 70px;
      height: 70px;
      object-fit: contain;
    }
  }
  .pageItem-panel {
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    column-gap: 10px;

    span {
      display: flex;
      align-items: center;
      column-gap: 5px;
    }
  }
  .pageItem-wrapper {
    display: flex;
    flex-wrap: wrap;
    column-gap: 40px;
    .pageItem-left {
      width: 45%;
      flex-grow: 1;

      .pageItem-photos {
        .pageItem-mainPhoto {
          height: 440px;
          img {
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
        }
        .pageItem-galery {
          display: flex;
          width: 100%;
          justify-content: space-evenly;

          &--item {
            border-radius: 4px;
            width: 80px;
            height: 80px;

            cursor: pointer;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          .galery-active {
            border: 2px solid #1c472f;
          }
        }
      }

      .pageItem-tabs {
        margin-top: 20px;
        .v-slide-group__content {
          .v-tab {
            margin-left: 0 !important;
            color: #000;
            text-transform: none;
          }
        }
      }

      .pageItem-characteristics {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        &--item {
          display: block;
          overflow-x: hidden;

          &:before {
            float: left;
            width: 0;
            white-space: nowrap;
            content: ". . . . . . . . . . . . . . . . . . . . "
              ". . . . . . . . . . . . . . . . . . . . "
              ". . . . . . . . . . . . . . . . . . . . "
              ". . . . . . . . . . . . . . . . . . . . ";
          }

          h5 {
            display: inline;
            padding: 0;
            overflow: hidden;
            background: #fff;
            color: rgba(34, 38, 45, 0.7);
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
          }

          h6 {
            background: #fff;
            display: inline;
            float: right;
            color: rgba(34, 38, 45, 1);
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
          }
        }
      }
      .description {
        margin-top: 20px;
      }
    }
    .pageItem-right {
      width: 45%;
      flex-grow: 1;

      .pageItem-actions {
        padding: 20px;
        box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;

        .pageItem-hasIs {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          column-gap: 5px;
          margin-bottom: 25px;
        }

        .pageItem-variable {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;

          h6 {
            width: 100%;
          }
          .pageItem-variable-buttons {
            width: 100%;
            display: flex;
            column-gap: 10px;

            .btn {
              margin: 0;

              color: #a5a5ad;
              border-color: #a5a5ad;
              cursor: pointer;
              border-width: 2px;

              &:hover,
              &[active] {
                background: transparent;
                color: var(--color);
                font-weight: bold;
                border-color: var(--color);
              }
              &:disabled {
                color: rgba(0, 0, 0, 0.2);
                font-weight: normal;
                background: transparent;
                border-width: 1px;
              }
            }
          }

          .pageItem-variable--count {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

            .item-count--wrapper {
              width: 180px;
              display: flex;
              justify-content: center;
              column-gap: 5px;
              border: 2px solid #e4e4e4;
              border-radius: 4px;
              height: 50px;
              margin-bottom: 32px;

              input {
                text-align: center;
                width: 40px;
                outline: none;
              }

              .item-count-icon {
                cursor: pointer;

                &::after {
                  display: none;
                }
              }
            }

            .pageItem-variable--price {
              margin-bottom: 20px;
              span,
              h3 {
                font-weight: 300;
                font-size: 16px;
                line-height: 19px;
                color: #1f2021;
              }

              b {
                font-size: 36px;
                line-height: 43px;
              }
            }

            .pageItem-variable--backet {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              column-gap: 20px;

              .btn {
                height: 50px;
              }

              .pageItem-variable--salePrice {
                width: 100%;
                margin-top: 20px;

                .item-bottom-price {
                  display: flex;
                  flex-direction: column;
                  row-gap: 3px;
                  .item-bottom-el {
                    padding: 0px 8px;
                    display: flex;
                    justify-content: space-between;
                    border: 1px solid #e4e4e4;
                    border-radius: 4px;
                    color: #969696;
                  }
                }
              }
            }
          }
        }

        .primary--text {
          color: var(--color) !important;
        }
      }
      .pageItem-delivery {
        margin-top: 30px;
        padding: 20px;
        box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;

        .pageItem-delivery--item {
          .logotips {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 10px;
            flex-wrap: wrap;
          }
        }

        h5 {
          color: rgba(34, 38, 45, 0.8);
        }
      }
    }

    .pageItem-items {
      margin-top: 55px;
      width: 100%;
      display: flex;
      column-gap: 15px;
      flex-wrap: wrap;

      h2 {
        width: 100%;
        margin-bottom: 25px;
      }

      .pageItem-items-item {
        width: 19%;
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .pageItem .pageItem-wrapper .pageItem-items .pageItem-items-item {
    width: 24%;
  }
}
@media screen and (max-width: 1265px) {
  .pageItem .pageItem-wrapper .pageItem-items .pageItem-items-item {
    width: 30%;
  }
}
@media screen and (max-width: 960px) {
  .pageItem
    .pageItem-wrapper
    .pageItem-right
    .pageItem-actions
    .pageItem-variable
    .pageItem-variable--count
    .pageItem-variable--backet {
    row-gap: 20px;
  }
  .pageItem
    .pageItem-wrapper
    .pageItem-right
    .pageItem-actions
    .pageItem-variable
    .pageItem-variable--count
    .pageItem-variable--backet
    .btn {
    width: 100%;
  }
  .pageItem-info {
    .v-slide-group.v-item-group > .v-slide-group__prev {
      display: none !important;
    }
  }
  .pageItem
    .pageItem-wrapper
    .pageItem-left
    .pageItem-tabs
    .v-slide-group__content
    .v-tab {
    font-size: 12px;
  }
}
@media screen and (max-width: 760px) {
  .pageItem .pageItem-wrapper .pageItem-left {
    width: 100%;
  }
  .pageItem .pageItem-wrapper .pageItem-right {
    width: 100%;
    margin-top: 30px;
  }
  .pageItem .pageItem-wrapper .pageItem-items .pageItem-items-item {
    width: 48%;
  }
  .pageItem-variable--price {
    margin-top: 20px;
  }
  .pageItem-variable-buttons {
    .btn {
      flex-grow: 1;
    }
  }
  .pageItem
    .pageItem-wrapper
    .pageItem-right
    .pageItem-actions
    .pageItem-variable
    .pageItem-variable--count
    .item-count--wrapper {
    width: 100%;
  }
  .v-hl-btn-next.v-hl-btn-between {
    transform: translate(20%) !important;
  }
  .v-hl-btn-prev.v-hl-btn-between {
    transform: translate(-20%) !important;
  }
}
@media screen and (max-width: 420px) {
  .pageItem .pageItem-wrapper .pageItem-items .pageItem-items-item {
    width: 100%;
  }
}
</style>
