<template>
  <div class="backet">
    <v-dialog v-model="dialog_thanks" max-width="560">
      <v-card class="d-flex call-dialog thanks-dialog">
        <a @click="dialog_thanks = false" class="dialog-close"
          ><img src="../assets/img/close.svg" alt=""
        /></a>
        <v-card-text>
          <h3>Спасибо!</h3>
          <div class="thanks-text">
            <p>
              Мы свяжемся с вами в ближайшее время <br />
              График работы: пн-пт, 9:00 - 18:00
            </p>
            <button @click="dialog_thanks = false" class="btn btn-invert">
              Вернуться к покупкам
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_error" max-width="560">
      <v-card class="d-flex call-dialog thanks-dialog">
        <a @click="dialog_error = false" class="dialog-close"
          ><img src="../assets/img/close.svg" alt=""
        /></a>
        <v-card-text>
          <h3>Упс!</h3>
          <div class="thanks-text">
            <p>
              Что-то пошло не так <br />
              Пожалуйста попробуйте позднее
            </p>
            <button @click="dialog_error = false" class="btn btn-invert">
              Вернуться к покупкам
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <h1>
      Корзина
      <a class="backet-clear" v-if="backet_count !== 0" @click="deleteAllItems">
        <img src="../assets/img/refresh.svg" alt="" /> Очистить</a
      >
    </h1>
    <div class="backet_wrapper">
      <div class="backet_main">
        <div class="backet_items">
          <div class="backet-item" v-for="item in backet_items" :key="item.id">
            <img
              class="backet-item--logo"
              :src="url + 'images/' + item.image"
              alt=""
            />
            <div class="backet-item--title">
              <h4>
                <router-link
                  :to="
                    '/item?id=' +
                    splitId(item.id)[0] +
                    '&atr=' +
                    splitId(item.id)[1]
                  "
                  >{{ superTitle(item) }}</router-link
                >
              </h4>
              <!-- <button @click="addFavoiteItem(item)" title="Избранное">
                <img
                  v-if="item.favorites"
                  src="../assets/img/heartFavActive.svg"
                  alt=""
                /><img v-else src="../assets/img/heartFav.svg" alt="" />
              </button> -->
              <button @click="deleteItem(item)" title="Удалить">
                <img src="../assets/img/Trash.svg" alt="" />
              </button>
            </div>
            <div class="backet-item--price">
              <span class="backet-price">
                <b
                  >{{
                    item.sale_price
                      ? prettify(item.sale_price)
                      : prettify(item.price)
                  }}
                  ₽</b
                >
                /шт</span
              >
              <div class="item-count--wrapper">
                <v-icon class="item-count-icon" @click="countItemMinus(item)"
                  >mdi-minus</v-icon
                >
                <input type="text" class="item-count" :value="item.count" />
                <v-icon class="item-count-icon" @click="countItemPlus(item)"
                  >mdi-plus</v-icon
                >
              </div>
              <span class="backet-item--totalPrice"
                >{{ itemTotalPrice(item) }} ₽
              </span>
            </div>
          </div>
        </div>
        <div class="backet_form" v-if="backet_count !== 0">
          <h2>Оформление заказа</h2>
          <form class="backet-form">
            <h4>Контактные данные получателя</h4>

            <label class="backet-form--label">
              Имя/Компания *
              <input
                type="text"
                name="name"
                v-model="uName"
                placeholder="Введите имя или название компании"
              />
            </label>
            <label class="backet-form--label">
              Телефон *
              <input
                type="tel"
                maxlength="11"
                name="phone"
                @keydown="onlyNumber"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                v-model="uPhone"
                placeholder="Введите телефон"
              />
            </label>
            <label class="backet-form--label">
              E-mail *
              <input
                type="text"
                name="email"
                v-model="uEmail"
                placeholder="Введите e-mail"
              />
            </label>
            <h4>Способ доставки</h4>
            <div class="backet-form--radios">
              <div
                class="backet-form--radio"
                :class="deliveryIs ? 'active-border' : ''"
              >
                <input
                  id="deliveryRadio"
                  type="radio"
                  v-model="deliveryIs"
                  :value="true"
                />
                <label for="deliveryRadio">
                  Доставка
                  <p>
                    Доставка по Санкт-Петербургу (в предалх КАД), Москве (в
                    предалх МКАД), Екатеринбургу дата и детали согласовываются с
                    менеджером
                  </p>
                </label>
              </div>
              <div
                class="backet-form--radio"
                :class="!deliveryIs ? 'active-border' : ''"
              >
                <input
                  id="selfRadio"
                  type="radio"
                  v-model="deliveryIs"
                  :value="false"
                />
                <label for="selfRadio">
                  Самовывоз
                  <p>
                    Санкт-Петербург, Москва, Екатеринбург, наличие товара
                    согласовывается менеджером
                  </p>
                </label>
              </div>
            </div>

            <div class="backet-form--delivery" v-if="deliveryIs">
              <label class="backet-form--label">
                Адрес доставки *
                <input
                  type="text"
                  name="address"
                  v-model="uAddress"
                  placeholder="Введите адрес"
                />
              </label>
              <label class="backet-form--label">
                Лицо, принимающее груз *
                <input
                  type="text"
                  name="fio"
                  v-model="uFIO"
                  placeholder="Введите ФИО"
                />
              </label>
              <label class="backet-form--label">
                Контактный телефон *
                <input
                  type="text"
                  name="udopphone"
                  v-model="uDopPhone"
                  placeholder="Введите телефон"
                />
              </label>
            </div>

            <h4>Способ оплаты</h4>
            <div class="backet-form--radios">
              <div
                class="backet-form--radio"
                :class="cashIs ? 'active-border' : ''"
              >
                <input
                  id="cashRadio"
                  type="radio"
                  v-model="cashIs"
                  :value="true"
                />
                <label for="cashRadio">
                  Наличными
                  <p>
                    Оплата наличными или картой при получении товара курьером
                    или в офисе компании
                  </p>
                </label>
              </div>
              <div
                class="backet-form--radio"
                :class="!cashIs ? 'active-border' : ''"
              >
                <input
                  id="cardRadio"
                  type="radio"
                  v-model="cashIs"
                  :value="false"
                />
                <label for="cardRadio">
                  Безналичная оплата от юр. лица
                  <p>
                    Счет выставляется после согласования с менеджером стоимости,
                    наличия и условий поставки.
                  </p>
                </label>
              </div>
            </div>

            <label class="backet-form--label">
              Комментарий к заказу
              <textarea
                name="comment"
                v-model="uComment"
                placeholder="Введите комментарий"
              />
            </label>
          </form>
        </div>
      </div>
      <div class="backet_total">
        <div class="backet_total-main">
          <h2>Ваш заказ</h2>
          <h5 class="backet_total-headers">Товары</h5>
          <span class="backet_total-value">{{ backet_count }} шт</span>
          <h5 class="backet_total-headers">Доставка</h5>
          <span class="backet_total-value">от 10 000* ₽</span>
          <h5 class="backet_total-headers">Дата</h5>
          <span class="backet_total-value">Согласовывается с менеджером</span>

          <h3>Итого</h3>
          <b class="backet_total-value backet_total-price"
            >{{ prettify(backetTotalPrice()) }}<sup>*</sup> ₽</b
          >
          <button class="btn" @click="sendForm" :disabled="!dataIsFull">
            Оформить заказ
          </button>
          <span class="backet-dopInfo"
            >* возможно изменение цены в зависимости от курса, корректную цену
            на момент заказа сообщит менеджер</span
          >
          <span class="backet_total-main--hint">
            Нажимая на кнопку, вы даёте согласие на обработку персональных
            данных и соглашаетесь с <a href=""> политикой конфиденциальности</a>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import api from "../api";

export default {
  name: "Backet",
  computed: {
    ...mapGetters(["backet_items", "backet_count"]),
    dataIsFull() {
      if (this.uName !== "" && this.uPhone !== "" && this.uEmail !== "") {
        if (this.deliveryIs) {
          if (this.uAddress !== "" && this.uFIO !== "" && this.uDopPhone !== "")
            return true;
          else return false;
        }
        return true;
      }
      return false;
    },
  },
  data: () => ({
    dialog_thanks: false,
    dialog_error: false,
    url: process.env.VUE_APP_ROOT_API,
    active: true,
    uName: "",
    uPhone: "",
    uEmail: "",
    uAddress: "",
    uFIO: "",
    uDopPhone: "",
    uComment: "",
    deliveryIs: true,
    cashIs: true,
  }),
  methods: {
    splitId(id) {
      return id.split("-");
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 8) {
        $event.preventDefault();
      }
    },
    async sendForm() {
      let data = {
        name: this.uName,
        phone: this.uPhone,
        email: this.uEmail,
        address: this.uAddress,
        fio: this.uFIO,
        dopphone: this.uDopPhone,
        comment: this.uComment,
        delivery: String(this.deliveryIs),
        cashis: String(this.cashIs),
        total_price: this.backetTotalPrice(),
        items: [],
      };
      this.backet_items.forEach((i) => {
        data.items.push({
          [String(i.attribute.id)]: i.count,
        });
      });

      console.log(data);
      let res = await api.sendForm(data);
      console.log(res);
      if (res) {
        this.dialog_thanks = true;
        this.deleteAllItems();
      } else {
        this.dialog_error = true;
      }
    },
    itemTotalPrice(item) {
      if (item.sale_price) {
        return this.prettify(item.sale_price * item.count);
      }
      return this.prettify(item.price * item.count);
    },
    prettify(num) {
      var n = num.toString();
      var separator = " ";
      return n.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + separator);
    },
    deleteItem(item) {
      this.$store.dispatch("delete_item", item);
    },
    deleteAllItems() {
      this.$store.dispatch("delete_all_items");
    },
    backetTotalPrice() {
      let sum = 0;
      this.backet_items.forEach((e) => {
        if (e.sale_price) {
          sum += e.sale_price * e.count;
        } else {
          sum += e.price * e.count;
        }
      });
      return sum;
    },
    countItemMinus(item) {
      this.$store.dispatch("item_minus_count", item);
    },
    countItemPlus(item) {
      this.$store.dispatch("item_plus_count", item);
    },
    superTitle(item) {
      // console.log(item);
      let superTitle = item.title + " ";

      item.attribute.param.forEach((el) => {
        if (el.value)
          superTitle += el.name + " " + el.value + " " + el.unit + ", ";
        if (el.additional)
          superTitle +=
            el.name + " - " + String(el.additional).toLowerCase() + ", ";
      });
      superTitle = superTitle.slice(0, -2);
      return superTitle;
    },
  },
  components: {},
  mounted() {
    console.log(this.backet_items);
  },
};
</script>
<style lang="scss">
.backet-dopInfo {
  font-size: 10px;
  font-style: italic;
}
.backet {
  h1 {
    display: flex;
    column-gap: 10px;
    align-items: center;
  }
  .backet-clear {
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
    color: #afafaf;
  }

  .backet_wrapper {
    display: flex;
    column-gap: 30px;
    justify-content: space-between;

    .backet_main {
      width: calc(100% - 350px);
    }
    .backet_total {
      position: sticky;
      top: 130px;
      width: 320px;
      height: 420px;

      &-main {
        display: flex;
        flex-wrap: wrap;
        row-gap: 15px;
        background: #ffffff;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        padding: 20px;

        h2 {
          width: 100%;
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 25px;
        }

        h3 {
          width: 40%;
          font-size: 18px;
        }
        .backet_total-price {
          font-size: 22px;
        }

        .backet_total-headers {
          color: #7e7e7e;
          font-size: 14px;
          display: flex;
          width: 40%;
          font-weight: normal;
        }
        .backet_total-value {
          font-weight: bold;
          width: 60%;
        }
        .btn {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  .backet-item {
    display: flex;
    align-items: center;
    column-gap: 20px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.12);
    padding-top: 20px;
    padding-bottom: 30px;

    .backet-item--logo {
      width: 90px;
    }

    .backet-item--title {
      display: flex;
      flex-wrap: wrap;
      row-gap: 15px;
      column-gap: 10px;
      flex-shrink: 1;

      h4 {
        width: 100%;

        a {
          color: #000;
        }
      }
    }

    .backet-item--price {
      flex-grow: 1;
      display: flex;
      align-items: center;
      column-gap: 15px;

      .backet-price {
        text-align: right;
        width: 100px;
        color: #9e9e9e;

        b {
          color: #000;
        }
      }

      .backet-item--totalPrice {
        min-width: 80px;
      }
    }
  }

  .item-count--wrapper {
    display: flex;
    justify-content: center;
    column-gap: 5px;
    border: 2px solid #e4e4e4;
    border-radius: 4px;
    height: 40px;

    input {
      text-align: center;
      width: 40px;
      outline: none;
    }

    .item-count-icon {
      cursor: pointer;
      &::after {
        display: none;
      }
    }
  }
  .backet_form {
    margin-top: 50px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 30px;

    .backet-form {
      margin-top: 30px;
      display: flex;
      flex-direction: column;

      h4 {
        margin-bottom: 15px;
      }

      .backet-form--label {
        margin-bottom: 25px;
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        input {
          outline: none;
          padding: 12px 15px;
          background: #ffffff;
          border: 1px solid #e4e4e4;
          border-radius: 4px;

          ::placeholder {
            color: #a2a2a2;
          }
        }

        textarea {
          outline: none;
          padding: 12px 15px;
          background: #ffffff;
          border: 1px solid #e4e4e4;
          border-radius: 4px;
          resize: none;

          ::placeholder {
            color: #a2a2a2;
          }
        }
      }
      .backet-form--radios {
        display: flex;
        justify-content: space-between;
      }
      .backet-form--radio {
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        width: 48%;
        padding-top: 15px;
        padding-right: 15px;
        padding-bottom: 10px;
        padding-left: 50px;
        border: 2px solid transparent;
        margin-bottom: 25px;

        input {
          display: none;

          &:checked ~ label {
            ::before {
              background: url(../assets/img/radioChecked.svg);
            }
          }
        }

        &.active-border {
          border: 2px solid var(--color);
        }

        label {
          position: relative;
          font-weight: bold;

          ::before {
            content: "";
            display: block;
            position: absolute;
            width: 20px;
            height: 20px;
            background: url(../assets/img/radio.svg) no-repeat;
            background-size: contain;
            left: -35px;
            top: 3px;
          }
          p {
            margin-top: 10px;
            font-weight: normal;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1265px) {
  .backet .backet-item {
    flex-wrap: wrap;
  }
  .backet .backet-item .backet-item--price {
    flex-grow: 0;
  }
  .backet .backet-item .backet-item--logo {
    width: auto;
    max-width: 300px;
  }
}
@media screen and (max-width: 960px) {
  .backet .backet_wrapper {
    flex-wrap: wrap;

    .backet_main {
      width: 100%;
    }
  }
  .backet .backet_wrapper .backet_total {
    position: static;
    width: 100%;
    height: auto;
  }
  .backet .backet_wrapper .backet_total-main {
    margin-top: 40px;
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .backet .backet-item .backet-item--logo {
    max-width: 100%;
  }
  .backet .backet_form .backet-form .backet-form--radios {
    flex-wrap: wrap;
  }
  .backet .backet_form .backet-form .backet-form--radio {
    width: 100%;
  }
}
</style>
