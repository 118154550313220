<template>
  <div class="catalog">
    <div class="breadcrumbs">
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    </div>
    <h1>{{ page_title }}</h1>
    <div class="catalog__main-categories">
      <div class="catalog__main-card" v-for="cat in categories" :key="cat.id">
        <h4>
          <router-link :to="'/category?id=' + cat.id">{{
            cat.name
          }}</router-link>
        </h4>

        <img :src="url + 'images/' + cat.image" alt="" />
        <p v-for="item in cat.items" :key="item.id">
          <router-link :to="'/item?id=' + item.id">{{ item.name }}</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Catalog",
  computed: {
    ...mapGetters(["page_title", "categories"]),
  },
  data: () => ({
    url: process.env.VUE_APP_ROOT_API,
    target: "",
    breadcrumbs: [
      {
        text: "Главная",
        disabled: false,
        href: "/",
      },
      {
        text: "Каталог",
        disabled: false,
        href: "",
      },
    ],
  }),
  components: {},
  mounted() {
    this.$store.dispatch("set_title", "Каталог");
  },
};
</script>
<style lang="scss">
.breadcrumbs {
  .v-breadcrumbs {
    padding: 0;
    a {
      color: #a1a1a1;
      font-size: 14px;
    }
  }
}
.catalog__main-categories {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.catalog__main-card {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  border-radius: 4px;
  padding: 20px;
  width: 30%;

  p a {
    color: #000;
    &:hover {
      color: var(--color-hover);
    }
  }

  h4 {
    margin-bottom: 20px;
    a {
      color: #000;
      &:hover {
        color: var(--color);
      }
    }
  }
  > img {
    position: absolute;
    width: 100px;
    right: 0;
  }
}
@media screen and (max-width: 1175px) {
  .catalog__main-card {
    width: 48%;
  }
}
@media screen and (max-width: 750px) {
  .catalog__main-card {
    width: 100%;
  }
}

@media screen and (max-width: 360px) {
  .catalog__main-card h4 {
    width: 160px;
  }
  .catalog__main-card p:first-of-type {
    width: 160px;
  }
}
</style>
