<template>
  <div class="delivery">
    <h1
      style="
        color: #fff;
        background: var(--color);
        padding: 5px 0;
        padding-left: 5px;
      "
    >
      Доставка
    </h1>

    <h5 class="delivery_mainInfo">
      Время обработки заказов: <br />
      Пн.-Пт. - с 9:00 до 18:00

      <br /><br /><br />

      Заказ оформляется через корзину, после формирования заказа, с вами <br />
      свяжется менеджер для уточнения деталей и выписки счета.
    </h5>

    <h2>Стоимость и способы доставки:</h2>
    <h5>Самовывоз</h5>
    <div class="delivery_map delivery_Moscow">
      <iframe
        src="https://yandex.ru/map-widget/v1/?um=constructor%3A4317780ec29904822a2802647cd38e4eb98c431740fa97ca908b9b3bebb0afe4&amp;source=constructor"
        width="330px"
        height="330px"
        frameborder="0"
        style="border-radius: 10px"
      ></iframe>
      <div class="delivery_city">
        <h6>Склад - Москва</h6>
        <span
          ><img src="../assets/img/phoneFooter.svg" alt="" />+7 (495)
          652-23-66</span
        >
        <span
          ><img
            src="../assets/img/email.svg"
            alt=""
          />izoprint@izoprint.ru</span
        >
        <span
          ><img src="../assets/img/clock.svg" alt="" />Пн.-Пт. - с 8:00 до
          17:00</span
        >
        <span
          ><img src="../assets/img/mark.svg" alt="" />город Москва, 2-й <br />
          Иртышский пр., д.4, с.1А</span
        >
      </div>
      <div class="delivery_car">
        <img src="../assets/img/time.svg" alt="" />
        <h6>
          Бесплатная доставка по Москве в пределах МКАД при заказе от 40
          тыс.рублей
        </h6>
      </div>
    </div>
    <div class="delivery_map delivery_Spb">
      <iframe
        src="https://yandex.ru/map-widget/v1/?lang=ru_RU&scroll=true&um=constructor%3A2b0d10f477b5ac90516926943acd7000e3a40a205c51f98227d99ed97c1fca9a"
        width="330px"
        height="330px"
        frameborder="0"
        style="border-radius: 10px"
      ></iframe>
      <div class="delivery_city">
        <h6>Склад - Санкт-Петербург</h6>
        <span
          ><img src="../assets/img/phoneFooter.svg" alt="" />+7 (921)
          928-33-76</span
        >
        <span
          ><img src="../assets/img/email.svg" alt="" />sklad@izoprint.ru</span
        >
        <span
          ><img src="../assets/img/clock.svg" alt="" />Пн.-Пт. - с 8:00 до
          17:00</span
        >
        <span
          ><img src="../assets/img/mark.svg" alt="" />г. Санкт-Петербург, ул.
          <br />
          Салова, д. 35, лит. Б</span
        >
      </div>
      <div class="delivery_car">
        <img src="../assets/img/time.svg" alt="" />
        <h6>
          Бесплатная доставка по СПБ (в пределах 20 км) при заказе от 10
          тыс.рублей
        </h6>
      </div>
    </div>
    <div class="dalivery_partners">
      <h2>Наши партнеры:</h2>

      <h5>Деловые линии</h5>
      <h5>ПЭК (доставка до терминала)</h5>
      <h5>Любая другая транспортная компания</h5>
    </div>
  </div>
</template>

<script>
export default {
  name: "Delivery",
  data: () => ({
    dialog: false,
  }),
  components: {},
};
</script>
<style lang="scss">
.delivery {
  .delivery_mainInfo {
    margin-top: 15px;
    padding-top: 20px;
    padding-bottom: 40px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  h2 {
    margin: 50px 0;
  }
  .delivery_map {
    margin-top: 25px;
    margin-bottom: 25px;
    display: flex;

    .delivery_city {
      margin-left: 30px;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      margin-top: 40px;

      span {
        display: flex;
        align-items: center;
        column-gap: 5px;
      }
    }
    .delivery_car {
      display: flex;
      align-items: center;
      column-gap: 20px;
      height: 64px;
      width: 40%;
      margin-left: auto;
      margin-top: 40px;

      img {
        width: 64px;
        height: 64px;
      }
    }
  }
  .delivery_Spb {
    margin-top: 50px;
  }

  .dalivery_partners {
    margin-top: 55px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);

    h5 {
      margin-bottom: 15px;
    }
  }
}

@media screen and (max-width: 960px) {
  .delivery {
    .delivery_map {
      flex-wrap: wrap;
      justify-content: space-between;
      iframe {
        width: 100% !important;
        margin-bottom: 20px;
      }
      .delivery_city {
        margin: 0;
      }
      .delivery_car {
        margin: 0;
        height: auto;
        width: 50%;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .delivery {
    .delivery_map {
      .delivery_car {
        margin: 0;
        margin-top: 20px;
        height: auto;
        width: 100%;

        img {
          width: 48px;
          height: 48px;
        }
      }
    }
  }
}
</style>
