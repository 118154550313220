import Vue from "vue";
import VueRouter from "vue-router";
import Backet from "../components/Backet.vue";
import Item from "../components/Item.vue";
import Contacts from "../components/Contacts.vue";
import Category from "../components/Category.vue";
import Delivery from "../components/Delivery.vue";
import Favorites from "../components/Favorites.vue";
import About from "../components/About.vue";
import Home from "../views/Home.vue";
import Catalog from "../views/Catalog.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/catalog",
    name: "Catalog",
    component: Catalog,
  },
  {
    path: "/category",
    name: "Category",
    component: Category,
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: Contacts,
  },
  {
    path: "/backet",
    name: "Backet",
    component: Backet,
  },
  {
    path: "/item",
    name: "Item",
    component: Item,
  },
  {
    path: "/delivery",
    name: "Delivery",
    component: Delivery,
  },
  {
    path: "/favorites",
    name: "Favorites",
    component: Favorites,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
