<template>
  <div class="about_page">
    <v-dialog v-model="dialog" max-width="560">
      <v-card class="d-flex call-dialog">
        <a @click="dialog = false" class="dialog-close"
          ><img src="../assets/img/close.svg" alt=""
        /></a>
        <v-card-text>
          <h3>Обратный звонок</h3>
          <div class="form">
            <label for="call-name1">Ваше имя <b color="red ">*</b></label>
            <input
              required
              id="call-name1"
              type="text"
              placeholder="Введите имя"
              v-model="name"
            />
            <label for="call-phone1">Телефон <b color="red ">*</b></label>
            <input
              required
              id="call-phone1"
              type="tel"
              placeholder="Введите телефон"
              maxlength="11"
              @keydown="onlyNumber"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              v-model="phone"
            />
            <label for="comment">Комментарий </label>
            <input
              id="comment"
              type="text"
              placeholder="Комментарий"
              v-model="comment"
            />
            <button
              :disabled="name == '' || phone == ''"
              @click="
                sendForm();
                dialog = false;
                dialog_thanks = true;
              "
              class="btn"
            >
              Отправить
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_thanks" max-width="560">
      <v-card class="d-flex call-dialog thanks-dialog">
        <a @click="dialog_thanks = false" class="dialog-close"
          ><img src="../assets/img/close.svg" alt=""
        /></a>
        <v-card-text>
          <h3>Спасибо!</h3>
          <div class="thanks-text">
            <p>
              Мы свяжемся с вами в ближайшее время <br />
              График работы: пн-пт, 9:00 - 18:00
            </p>
            <button @click="dialog_thanks = false" class="btn btn-invert">
              Вернуться к покупкам
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <div class="about" id="about">
      <h1 style="color: #fff; background: var(--color)">О нас</h1>
      <div class="about_main">
        <div class="about_main-left">
          <p>
            IZOPRINT - это 20 лет на рынке полиграфических материалов и
            оборудования! <br />
            Мы помогаем подбирать комплексные решения для задач вашего бизнеса.
          </p>
          <h6>
            <img src="../assets/img/time.svg" alt="" /> Работаем по всем
            регионам России
          </h6>
          <h6>
            <img src="../assets/img/bank.svg" alt="" /> Решения для любых задач
          </h6>
          <h6>
            <img src="../assets/img/manager.svg" alt="" /> Индивидуальная работа
            с каждым заказчиком
          </h6>
          <h6>
            <img src="../assets/img/map.svg" alt="" /> Доставка до двери по всей
            стране
          </h6>
        </div>
        <div class="about_main-right">
          <img src="../assets/img/logoHor.png" alt="" />
        </div>
      </div>
    </div>

    <div class="about__items">
      <h2>Мы предлагаем</h2>
      <div class="about__items_wrapper">
        <div class="about__items-left">
          <h4>МАТЕРИАЛЫ:</h4>
          <ul>
            <li><span>Пленки для ламинирования (холодного/горячего);</span></li>
            <li>
              <span
                >Магнитные материалы (магнитный винил, лента, мягкое
                железо);</span
              >
            </li>
            <li>
              <span
                >Широкоформатная печать (магнитный винил под печать,
                ферропленка, самоклеящаяся пленка);</span
              >
            </li>
            <li>
              <span
                >Металлическая фурнитура (для папок, кольцевые,
                прижимные);</span
              >
            </li>
            <li>
              <span
                >Материалы для календарей (бобины, курсоры, ригели,
                люверсы).</span
              >
            </li>
          </ul>
        </div>
        <div class="about__items-right">
          <h4>ОБОРУДОВАНИЕ:</h4>
          <ul>
            <li>
              <span
                >Для любых стадий печатного и производственного
                процессов.;</span
              >
            </li>
            <li>
              <span
                >Поставляем из Европы и Азии новое и подержанное
                оборудование;</span
              >
            </li>
            <li><span>Подбираем под задачи и требования заказчика;</span></li>
            <li><span>Помогаем с техническим сопровождением;</span></li>
            <li><span>Оформляем сопутствующую документацию.</span></li>
          </ul>
        </div>
      </div>
    </div>

    <div class="callback">
      <h2>Обратная связь</h2>
      <div class="callback_main">
        <p>
          Если у вас возникли вопросы, вы можете заказать «Обратный звонок»,
          «Задать вопрос» менеджерам через форму на сайте или «Написать
          директору» ваши пожелания, критику или выражение
          благодарности.
        </p>
        <div class="callback_buttons">
          <button @click="dialog = true" class="btn btn-invert">
            Заказать обратный звонок</button
          ><button @click="dialog = true" class="btn btn-invert">
            Задать вопрос менеджеру</button
          ><button @click="dialog = true" class="btn btn-invert">
            Написать директору
          </button>
        </div>
      </div>
    </div>

    <div class="objects">
      <h2>Наши материалы используют:</h2>
      <div class="objects_cards">
        <div class="object_card">
          <img src="../assets/img/nike.png" alt="" />
        </div>
        <div class="object_card">
          <img src="../assets/img/det_mir.png" alt="" />
        </div>
        <div class="object_card">
          <img src="../assets/img/beeline.png" alt="" />
        </div>
        <div class="object_card">
          <img src="../assets/img/magnit_cos.png" alt="" />
        </div>
        <div class="object_card">
          <img src="../assets/img/megafon.png" alt="" />
        </div>
        <div class="object_card">
          <img src="../assets/img/sport_master.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../api";

export default {
  name: "Contacts",
  data: () => ({
    dialog: false,
    dialog_thanks: false,
    name: "",
    phone: "",
    comment: "",
  }),
  methods: {
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 8) {
        $event.preventDefault();
      }
    },
    async sendForm() {
      let data = {
        name: this.name,
        phone: this.phone,
        comment: this.comment,
      };
      await api.sendCallForm(data);

      this.name = "";
      this.phone = "";
      this.comment = "";
    },
  },
  components: {},
  mounted() {
    if (window.location.hash) {
      document.querySelector(window.location.hash).scrollIntoView(false);
    }
  },
};
</script>
