<template>
  <section class="home">
    <div class="banners">
      <v-carousel height="400" hide-delimiter-background show-arrows-on-hover>
        <v-carousel-item v-for="banner in banners" :key="banner.id">
          <v-sheet height="100%" class="banner">
            <a target="_blank" download :href="url + 'images/' + banner.banner_file">
              <img :src="url + 'images/' + banner.banner_image" alt="" />
            </a>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </div>
    <div class="home_categories">
      <div
        class="home_categories-item"
        v-for="category in categories"
        :key="category.id"
      >
        <h2>{{ category.name }}</h2>
        <div class="home_categories-list">
          <div
            class="home_categories-list--item"
            v-for="item in category.items"
            :key="item.id"
          >
            <a :href="'/item?id=' + item.id">
              <h5>{{ item.name }}</h5>
              <img :src="url + 'images/' + item.image" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="home_about mt-15">
      <h3 class="mb-4">
        «IZO Маркет» — интернет-магазин материалов и оборудования для полиграфии
        в Санкт-Петербурге, Москве и Екатеринбурге
      </h3>
      <p>
        В нашем магазине в СПб, Москве и Екатеринбурге можно выбрать и недорого
        купить магнитный винил, ферропленку, магнитное железо, магнитную ленту,
        а также пленки для ламинации. Сделайте покупку на сайте и с вами
        свяжется наш специалист, чтобы обсудить все детали. <br />
        <br />
        Заказы в СПб принимаются по телефону отдела продаж +7 (812) 327-60-60.
        <br />
        Заказы в Москве принимаются по телефону отдела продаж +7 (495)
        627-75-88. <br />
        Заказы в Екатеринбурге принимаются по телефону отдела продаж +7 (343)
        219-87-20.
      </p>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import api from "../api";

export default {
  name: "Home",
  components: {},
  computed: {
    ...mapGetters(["categories"]),
  },
  data: () => ({
    url: process.env.VUE_APP_ROOT_API,
    banners: [],
    items: [],
  }),
  mounted() {
    this.initItems();
    this.items = this.categories;

    this.items.forEach((el) => {
      el.items.forEach((i) => {
        if (i.image[0]) {
          i.image = i.image[0].name;
        }
      });
    });
  },
  methods: {
    async initItems() {
      let answer = await api.getBanners();

      if (answer) {
        this.banners = answer;

        this.banners.forEach((el) => {
            if (el.banner_image == null) {
              let index = this.banners.indexOf(el);
              this.banners.splice(index, index)
            }
        });
      }
    },
    checkSale(item) {
      let breakFor = false;
      item.sale_price = item.price;

      if (item.sale_price == item.price) {
        item.sale_price = "";
      }
      if (item.sales)
        item.sales.forEach((s) => {
          if (item.count >= s.count) {
            if (breakFor) return;
            item.sale_price = s.price;
            breakFor = true;
          }
        });
      this.totalPrice = this.count * this.price;
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 8) {
        $event.preventDefault();
      }
    },
    prettify(num) {
      var n = num.toString();
      var separator = " ";
      return n.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + separator);
    },
    addItem(item) {
      this.$store.dispatch("add_item_in_backet", item);
    },
    itemInBacket(item) {
      let items = JSON.parse(localStorage.getItem("items"));
      if (items) {
        if (items.find((el) => el.id == item.id)) {
          return true;
        }
      }
      return false;
    },
    itemPlusCount(item) {
      if (this.itemInBacket(item)) {
        this.$store.dispatch("item_plus_count", item);
        let items = JSON.parse(localStorage.getItem("items"));
        item.count = items.find((el) => el.id == item.id).count;
      } else {
        if (item.count <= 1) {
          item.count = 1;
        }
        item.count = item.count + 1;
      }
      this.checkSale(item);
    },
    itemMinusCount(item) {
      if (this.itemInBacket(item)) {
        if (item.count <= 1) {
          item.count = 1;
        } else {
          this.$store.dispatch("item_minus_count", item);
          let items = JSON.parse(localStorage.getItem("items"));
          item.count = items.find((el) => el.id == item.id).count;
        }
      } else {
        if (item.count <= 1) {
          item.count = 1;
        } else {
          item.count = item.count - 1;
        }
      }
      this.checkSale(item);
    },
    changeBtn(e) {
      let elem = e.srcElement;
      elem.innerHTML = "В корзине";
      elem.style.pointerEvents = "none";
      elem.classList.add("btn-invert");
    },
    addFavoiteItem(item) {
      this.$store.dispatch("toogle_favorites_item", item);
      item.favorites = !item.favorites;
    },
    checkFavorites(item) {
      let items = JSON.parse(localStorage.getItem("favorites-items"));
      if (items)
        if (items.find((el) => el.id == item.id)) {
          return true;
        }
      return false;
    },
  },
};
</script>
<style lang="scss">
.banners {
  .v-carousel {
    border-radius: 8px;
    height: auto !important;
    max-height: 400px;

    .v-carousel__item {
      height: auto !important;
      max-height: 400px;
    }
  }
  .banner {
    img {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .v-window__next,
  .v-window__prev {
    background: var(--color);
  }

  .v-carousel__controls {
    .v-carousel__controls__item {
      color: var(--color);
    }
  }
}

.home_categories {
  .home_categories-item {
    display: flex;
    flex-wrap: wrap;

    h2 {
      color: var(--color);
      width: 100%;
      margin-top: 20px;
    }

    .home_categories-list {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      margin-top: 20px;
      width: 100%;

      &--item {
        a {
          display: flex;
          width: 100%;
          height: 100%;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          padding: 20px 15px;
          text-align: center;
        }

        background: #f0f2f1;
        border-radius: 4px;
        width: 230px;
        height: 230px;
        transition: 0.3s;

        h5 {
          color: #000;
        }

        img {
          width: 130px;
        }

        &:hover {
          background: #fff;
          box-shadow: 0px 3px 20px rgba(0, 5, 115, 0.1);
        }
      }
    }
  }
}

.home_hits {
  display: flex;
  margin-top: 70px;
  column-gap: 20px;

  h2 {
    margin-bottom: 20px;
  }

  .home_hits_left {
    width: 100%;
  }
  // .home_hits_right {
  //   width: 10%;
  // }

  .home_hits_list {
    display: flex;
    flex-wrap: wrap;

    .home_hits-item {
      width: calc(25% - 20px);
      margin: 10px;

      .item-status--new {
        font-size: 10px;
      }
      .item-status--sale {
        font-size: 10px;
      }
    }
  }
}

@media screen and (max-width: 1225px) {
  .home_categories .home_categories-item .home_categories-list--item {
    width: 32%;
  }
  .btn {
    line-height: 16px;
  }
}
@media screen and (max-width: 1100px) {
  .product-item {
    .item-buttons-wrapper {
      flex-wrap: wrap;

      .btn {
        width: 100%;
        margin-bottom: 5px;
      }
    }

    .item-status--sale,
    .item-status--new {
      font-size: 8px !important;
      padding: 2px 5px;
    }
  }
  .home_hits .home_hits_list .home_hits-item {
    width: calc(33% - 20px);
  }
}
@media screen and (max-width: 960px) {
  .home_categories .home_categories-item .home_categories-list--item {
    width: 48%;
  }
  .home_hits .home_hits_list .home_hits-item {
    width: calc(48% - 20px);
  }
  .product-item .item-status--sale,
  .product-item .item-status--new {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 600px) {
  .home_categories .home_categories-item .home_categories-list--item {
    width: 100%;
  }
  .home_hits .home_hits_list .home_hits-item {
    width: 100%;
  }

  .banner {
    height: 190px !important;
    a {
      display: block;
      position: relative;
      height: 100%;
    }
  }
}
</style>
