import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    page_title: "",
    city: localStorage.getItem("city") ? localStorage.getItem("city") : "",
    categories: [],
    backet: {
      items: localStorage.getItem("items")
        ? JSON.parse(localStorage.getItem("items"))
        : [],
      count: localStorage.getItem("count")
        ? Number(localStorage.getItem("count"))
        : 0,
    },
    favorites: {
      items: localStorage.getItem("favorites-items")
        ? JSON.parse(localStorage.getItem("favorites-items"))
        : [],
      count: localStorage.getItem("favorites-count")
        ? Number(localStorage.getItem("favorites-count"))
        : 0,
    },
  },
  getters: {
    page_title: (state) => {
      return state.page_title;
    },
    categories: (state) => {
      return state.categories;
    },
    backet_items: (state) => {
      return state.backet.items;
    },
    backet_count: (state) => {
      return state.backet.count;
    },
    favorites_items: (state) => {
      return state.favorites.items;
    },
    favorites_count: (state) => {
      return state.favorites.count;
    },
    city: (state) => {
      return state.city;
    },
  },
  mutations: {
    set_title: (state, payload) => {
      state.page_title = payload;
    },
    set_city: (state, payload) => {
      state.city = payload;
    },
    set_categories: (state, payload) => {
      state.categories = payload;
    },
    add_item: (state, payload) => {
      state.backet.count++;
      state.backet.items.push(payload);
    },
    changeCount: (state, payload) => {
      state.backet.items.find((el) => el.id == payload.id).count +=
        payload.count;
    },
    item_minus_count: (state, payload) => {
      state.backet.items.find((el) => el.id == payload.id).count--;

      let breakFor = false;
      payload.sale_price = payload.price;

      if (payload.sale_price == payload.price) {
        payload.sale_price = "";
      }

      if (payload.sales)
        for (var i = payload.sales.length - 1; i >= 0; i--) {
          let s = payload.sales[i];
          if (payload.count >= s.count) {
            if (breakFor) return;
            payload.sale_price = s.price;
            breakFor = true;
          }
        }
    },
    item_plus_count: (state, payload) => {
      state.backet.items.find((el) => el.id == payload.id).count++;
      let breakFor = false;
      payload.sale_price = payload.price;

      if (payload.sale_price == payload.price) {
        payload.sale_price = "";
      }
      if (payload.sales)
        payload.sales.forEach((s) => {
          if (payload.count >= s.count) {
            if (breakFor) return;
            payload.sale_price = s.price;
            breakFor = true;
          }
        });
    },
    delete_item: (state, payload) => {
      state.backet.count--;
      state.backet.items = state.backet.items.filter(
        (el) => el.id !== payload.id
      );
      if (state.backet.items.length == 0) {
        state.backet.count = 0;
      }
    },
    updateLocalStorage: (state) => {
      if (localStorage.getItem("items")) {
        let items = localStorage.getItem("items");
        let count = localStorage.getItem("count");

        items = state.backet.items;
        count = state.backet.count;

        localStorage.setItem("items", JSON.stringify(items));
        localStorage.setItem("count", JSON.stringify(count));
      } else {
        localStorage.setItem("items", JSON.stringify(state.backet.items));
        localStorage.setItem("count", JSON.stringify(state.backet.count));
      }
    },
    delete_all_items: (state) => {
      state.backet.items = [];
      state.backet.count = 0;
    },
    toogle_favorites_item: (state, payload) => {
      let item = state.favorites.items.find((el) => el.id == payload.id);
      if (item) {
        state.favorites.items = state.favorites.items.filter(
          (el) => el.id !== payload.id
        );
      } else {
        state.favorites.items.push(payload);
      }
      localStorage.setItem(
        "favorites-items",
        JSON.stringify(state.favorites.items)
      );
      state.favorites.count = state.favorites.items.length;
      localStorage.setItem("favorites-count", state.favorites.items.length);
    },
  },
  actions: {
    set_title: (context, payload) => {
      context.commit("set_title", payload);
    },
    set_city: (context, payload) => {
      context.commit("set_city", payload);
      localStorage.setItem("city", payload);
    },
    set_categories: (context, payload) => {
      context.commit("set_categories", payload);
    },
    add_item_in_backet: (context, payload) => {
      let item = context.state.backet.items.find((el) => el.id == payload.id);
      if (item) {
        context.commit("changeCount", payload);
      } else {
        context.commit("add_item", payload);
      }
      context.commit("updateLocalStorage");
    },
    item_minus_count: (context, payload) => {
      let item = context.state.backet.items.find((el) => el.id == payload.id);
      if (item.count == 1) {
        context.commit("delete_item", payload);
      } else {
        context.commit("item_minus_count", payload);
      }
      context.commit("updateLocalStorage");
    },
    item_plus_count: (context, payload) => {
      context.commit("item_plus_count", payload);
      context.commit("updateLocalStorage");
    },
    delete_item: (context, payload) => {
      context.commit("delete_item", payload);
      context.commit("updateLocalStorage");
    },
    delete_all_items: (context) => {
      context.commit("delete_all_items");
      context.commit("updateLocalStorage");
    },
    toogle_favorites_item: (context, payload) => {
      context.commit("toogle_favorites_item", payload);
    },
  },
  modules: {},
});
